import { INIT } from '../actions/app'


const payment = (state = {
  isFetching: false,
  hasErrors: false,
  data: [],
  errors: []
}, action) => {
  switch(action.type) {
    case INIT:
      return {
        isFetching: false,
        hasErrors: false,
        data: [],
        errors: []
      }
    default: 
      return state
  }
}

export default payment
