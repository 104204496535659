import $ from 'jquery';
import md5 from 'md5';

// const http_digest = 'Basic MTM4ZWU3ODQ1NWQ5NDk4YmI4NDZlYThjNGZhMjM1Mjk6MzYxMWIxNGViNTNhMjVlMjhmOTdlMDVhNWU2ZGMzNTA=';

export const LOGIN_RESET = 'LOGIN_RESET'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'

export const USER_DATA_REQUEST = 'USER_DATA_REQUEST'
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS'

export const USER_STATS_REQUEST = 'USER_STATS_REQUEST'
export const USER_STATS_SUCCESS = 'USER_STATS_SUCCESS'

export const TOGGLE_BOX = 'TOGGLE_BOX'
export const TOGGLE_SECTION = 'TOGGLE_SECTION'
export const VISIT_ALBUM='VISIT_ALBUM'
export const ADD_TRACK_PLAY='ADD_TRACK_PLAY'
export const ADD_TRACK_FULLPLAY='ADD_TRACK_FULLPLAY'
export const USER_SYNCH = 'USER_SYNCH'

export const RESET_REQUEST = 'RESET_REQUEST'
export const RESET_RESPONSE = 'RESET_RESPONSE'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_FAILED = 'RESET_FAILED'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED  = 'REGISTER_FAILED'

export const EP = 'https://api.klassif.io'
// export const EP = 'http://localhost:5000'

const DEFAULT_DAYS = 30

const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
    isFetching:true
  }
}

const resetRequest = () => {
  return {
    type: RESET_REQUEST,
  }
}

const resetSuccess = (res) => {
  return {
    type: RESET_SUCCESS,
    data:res.data
  }
}

const resetFailed = (err) => {
  return {
    type: RESET_FAILED,
    error: (err) ? err.message : ""
  }
}

const resetResponse = () => {
  return {
    type: RESET_RESPONSE
  }
}

const loginSuccessful = (res) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    data: res.h
  }
}

const registerRequest = () => {
  return {
    type: REGISTER_REQUEST,
    isFetching:true
  }
}

const registerSuccessful = (res) => {
  return {
    type: REGISTER_SUCCESS,
    isFetching:false,
    data: res.h
  }
}

const registerFailed = (err) => {
  return {
    type: REGISTER_FAILED,
    isFetching:false,
    error: (err) ? err.message : ""
  }
}

const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST,
    isFetching:true
  }
}

const logoutSuccessful = (res) => {
  return {
    type: LOGOUT_SUCCESS,
    isFetching:false
  }
}

const loginFailed = (err) => {
  return {
    type: LOGIN_FAILED,
    isFetching:false,
    error: (err) ? err.message : ""
  }
}

const logoutFailed = (err) => {
  return {
    type: LOGOUT_FAILED,
    isFetching: false,
    error: err
  }
}

const userDataRequest = () => {
  return {
    type: USER_DATA_REQUEST,
    isFetching:true
  }
}

const userDataSuccess = (res) => {
  return {
    type: USER_DATA_SUCCESS,
    isFetching: false,
    data: res.data,
  }
}

const userStatsRequest = () => {
  return {
    type: USER_STATS_REQUEST,
    isFetching:true
  }
}

const userStatsSuccess = (res) => {
  return {
    type: USER_STATS_SUCCESS,
    isFetching:false,
    data:res.data,
  }
}

export const loginReset = () => {
  return {
    type: LOGIN_RESET,
    isFetching:false
  }
}

export const toggleBox = () => {
  return {
    type: TOGGLE_BOX,
    isFetching:false
  }
}

export const toggleSection = (section) => {
  return {
    type: TOGGLE_SECTION,
    data:section,
  }
}

export const resetpass = (salt, solt, pass) => (dispatch, getState) => {
  var state = getState()
  var token = state.ra.sid
  var ts = Date.now() / 1000
  var data = {
    sign: state.ra.sid,
    song: pass,
    sun: salt,
    mars: solt,
    ts: ts
  }

  dispatch( resetRequest() )

  const mySuccessCallback = res => {
    console.log(res, res.data)
    if(res.status === 401 || res.status === 403)
      dispatch( resetFailed({message:"Reset link expired"}) )
    else  
      dispatch( resetSuccess(res) )
  }
  
  const myErrorCallback = res => {
    console.log(res)
    dispatch( resetFailed({message:"Reset link expired"}) )
  }

  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+token);
  }

  

  $.ajax(
    {
      url:EP+'/user/resetpass',
      method:'POST',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data,
  });
}

export const reset = email => (dispatch, getState) => {
  var state = getState()
  var token = state.ra.sid

  dispatch( resetRequest() )

  const myCallback = res => {
    console.log(res)
    dispatch( resetResponse() )
  }
  /* const myErrorCallback = res => {
    console.log(res)
    dispatch( registerFailed(res) )
  } */
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+token);
    //req.setRequestHeader("", "S "+tok);
  }

  var data={email:email}
  console.log(EP+'/user/reset')
  $.ajax(
    {
      url:EP+'/user/reset',
      method:'POST',
      beforeSend: addHeaders,
      success: myCallback,
      error: myCallback,
      dataType: 'json',
      data: data
  });
}

export const register = (name, email, password, org) => (dispatch, getState) => {
  var state = getState()
  var token = state.ra.sid

  dispatch( registerRequest() )

  const mySuccessCallback = res => {
    console.log(res)
    if(res.status === 'error')
      dispatch( registerFailed(res) )
    else  
      dispatch( registerSuccessful(res) )
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch( registerFailed(res) )
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+token);
    //req.setRequestHeader("", "S "+tok);
  }

  var data={email:email, pass:password, name:name, org:org}
  console.log(EP+'/user/register')
  $.ajax(
    {
      url:EP+'/user/register',
      method:'POST',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data
  });
}

export const loginUser = (email, password) => (dispatch, getState) => {
  var state = getState()
  var token = state.ra.sid

  dispatch(loginRequest())

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(loginSuccessful(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch( loginFailed(res) )
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S " + token);
    //req.setRequestHeader("", "S "+tok);
  }

  var data={email:email, pass:password}
  console.log(EP+'/user/login')
  $.ajax(
    {
      url:EP+'/user/login',
      method:'POST',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data
  });
}

export const logoutUser = () => (dispatch, getState) => {
  var state = getState()
  var token = state.user.csfr

  dispatch(logoutRequest())

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(logoutSuccessful(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch(logoutFailed(res))
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+token);
  }

  $.ajax(
    {
      url: EP + '/user/logout',
      method: 'GET',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
  });
}

export const userData = () => (dispatch, getState) => {

  var state = getState()
  // var ts = Date.now() / 1000
  /* var data = {
    sign: state.ra.sid,
    song: md5(state.ra.sid + ts),
    ts: ts
  } */

  dispatch(userDataRequest())

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(userDataSuccess(res))
  }
  const myErrorCallback = res => {
    console.log(res)
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+ state.user.csfr); // hash
  }

  $.ajax(
    {
      url: EP + '/user/details',
      method: 'GET',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
  });
}

export const updateStatsDays = (days) => (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_STATS_DAYS',
      data: days
    })
}

export const userStats = () => (dispatch, getState) => {

  var state = getState()
  var ts = Date.now() / 1000
  var data = {
    sign: state.ra.sid,
    song: md5(state.ra.sid + ts),
    ts: ts
  }

  $.extend(data, {days: state.user.statsDays || DEFAULT_DAYS})

  dispatch( userStatsRequest() )

  const mySuccessCallback = res => {
    console.log(res)
    dispatch( userStatsSuccess(res) )
  }
  const myErrorCallback = res => {
    // console.log(res)
    // dispatch( paymentError(res) )
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+ state.user.csfr); // hash
  }

  $.ajax(
    {
      url: EP + '/user/stats',
      method: 'POST',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data,
  });
}

export const addVisit = (item) => (dispatch, getState) => {

  var state = getState();
  const token = state.user.token;
  if(!token && !state.user.open)
    return
  
  var act, 
      found=false, 
      visits = state.user.user.visits,
      size = visits.length
      ;

  for(var i=0; i<size; i++){
    act = visits.shift();
    if(act.visit === item._id) {
      act.count += 1;
      act.last=(new Date()).toUTCString();
      found=true;
    }
    visits.push(act);
    if(found)
      break;
  }

  if(!found) {
    act={
      visit : item._id,
      count : 1,
      last:(new Date()).toUTCString()
    }
    visits.push(act);
  }

  state.user.user.visits=visits
  dispatch( {type:VISIT_ALBUM, updated:state.user } )

  //leverage payload
  //notify( { a:act, u:{_id:state.user.user._id} }, 'ADD_VISIT', token);
}

export const uSynch = () => (dispatch, getState) => {

  var state = getState();
  const token = state.user.token;
  if(!token && !state.user.open)
    return
  
  /*var act, 
      found=false, 
      visits = state.user.user.visits;

  for(var i in visits){
    act = visits.shift();
    if( act.visit === item._id ) {
      act.count+=1;
      act.last=(new Date()).toUTCString();
      found=true;
    }
    visits.push(act);
    if(found)
      break;
  }

  if(!found) {
    act={
      visit : item._id,
      count : 1,
      last:(new Date()).toUTCString()
    }
    visits.push(act);
  }*/

  //state.user.user.visits=visits
  dispatch( {type:USER_SYNCH, data:(new Date()).toUTCString() } )

  //leverage payload
  //notify( { a:{ log: state.player.playedlog, access:state.user.access}, u:{_id:state.user.user._id} }, 'USER_SYNCH_LA', token);
}
