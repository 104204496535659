import React from 'react';
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import $ from 'jquery'

import { activation } from '../actions/app'
import { resetpass } from '../actions/user'
import { goHome } from '../actions/lib'


const debug = false


class Redir extends React.Component {

  static propTypes = {
    app: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      password_error: null,
      password_error1: null,
      redirect: false,
      back: false,
      cred: null,
      showReset: false
    }
  }

  componentDidMount(){

    $('header').hide();
    $('.site-footer').addClass('blue');

    var event = new Event('start-waves');
    if(event.type !== 'start-waves') {
      setTimeout(function(){
        var event = new CustomEvent('start-waves',  {"bubbles":true, "cancelable":false} );
        document.dispatchEvent(event);
      },10)
    } else {
      document.dispatchEvent(event);
    }

    const updateIt = () => {

      var subarr, 
        payload={},
        arr=this.props.location.search.replace('?','').split('&');

      payload.salt=this.props.match.params.sig

      for(var i in arr){
        subarr=arr[i].split('=')
        if(subarr[0]==='l')
          payload.solt=subarr[1]
        console.log(subarr)
      }
      console.log("eeeeoooooo")
      if(this.props.app.acts <= (Date.now()/1000)-60 ) {
        this.props.foo(payload.salt, payload.solt)
      }
    }

    const resetIt = () => {

      var subarr, 
        payload = {},
        state = this.state,
        arr = this.props.location.search.replace('?','').split('&');

      payload.salt = this.props.match.params.sig

      for(var i in arr){
        subarr=arr[i].split('=')
        if(subarr[0]==='l')
          payload.solt=subarr[1]
        console.log(subarr)
      }

      console.log(" - reset")
      state.showReset = true
      state.cred = [payload.salt, payload.solt]
      this.setState(state)
      this.props.history.push('/dashboard/reset/password')
    }

    if(!this.props.app.sid) {
      if( this.props.location.pathname.indexOf('activate') !== -1 )
        setTimeout( function(){ updateIt() }, 1000 );
      if( this.props.location.pathname.indexOf('reset') !==-1 )
        resetIt()
    }
    else { 
      if(Object.keys(this.props.match.params).indexOf('sig') !== -1) {
        if( this.props.location.pathname.indexOf('reset') !==-1 )
          resetIt()
        if( this.props.location.pathname.indexOf('activate') !==-1 )
          updateIt()
      } 
    }
  }

  componentWillMount(){
  }

  componentWillReceiveProps(next){ 
    let state = this.state
    if(this.props.isFetching && !next.user.isFetching) {
      if(!this.props.user.hasErrors) {
        state.back = true
        this.setState(state)
      }
    }
  }

  updatePass(){
    let err;
    let pass=$(this.refs.pass).val()
    let pass1=$(this.refs.pass1).val()
    let state = this.state
    let validPass =  (!pass  || pass===''  || pass.length  < 6) ? false : pass;
    let validPass1 = (!pass1 || pass1==='' || pass1.length < 6) ? false : pass1;
    
    if(!validPass) {
      state.password_error='Password min 6 characters'
      $(this.refs.pass).val("")
      err=true
    } 
    if(!validPass1) {
      state.password_error1='Password min 6 characters'
      $(this.refs.pass1).val("")
      err=true
    } 
    if(!err && validPass === validPass1){
      state.password_error=null
      state.password_error1=null

      if(state.cred)
        this.props.updatepass(state.cred[0],state.cred[1],validPass)

    } else {
      state.password_error='Passwords do not match'
      state.password_error1='Passwords do not match'
    }
    this.setState(state);
  }

  render() {
    if(debug)
      console.log("Redir", this.props)

    var redir = ""

    if(this.props.app.isActive && !this.props.app.isActivating) {
      redir = "Redirect done"
      return(<Redirect to={`/login?t=your-account-is-active`} />)
    } else {
      if(this.state.showReset) {
        redir=(<div>
          <div className="text">Enter new password</div>
          <div className={this.state.password_error ? "form-group has-error" : "form-group" }>
            <input type="password" className="form-control" ref="pass" placeholder={this.state.password_error ? this.state.password_error : "New password..." } />
          </div>
          <div className="text">Confirm new password</div>
          <div className={this.state.password_error1 ? "form-group has-error" : "form-group" }>
            <input type="password" className="form-control" ref="pass1" placeholder={this.state.password_error1 ? this.state.password_error1 : "Retype..." } />
          </div>
          { this.state.back && !this.props.user.hasErrors && <div className="inliner"><span className="text">Password reset succesful</span> <Link className="wl" to="/login">Login</Link></div>}
          { this.props.user.hasErrors && this.props.user.errors.map((e,i)=>{
              return(<div key={i} className="text">{e}</div>)
              })
            }
          <hr />
          { !this.props.user.hasErrors && <button className="btn btn-default" onClick={()=>{ this.updatePass()}} >Save</button> }
          { this.props.user.hasErrors && <div className="inliner"><span className="text">Resend</span> <Link className="wl" to="/reset">Reset</Link> <span className="text">email</span></div> }
          { this.props.isFetching && <div className="loading-white"></div> }
        </div>)
      } else {
        redir = (<div className="inliner"><span className="text">Just one second </span><div className="loading-white"></div></div>)
      }
    }

    return ( 
      <div className="login cls">
        <div className="center">
          <div className="col-md-3">
          </div> 
          <div className="col-md-3 headline">
            <span className="text gt">Welcome to</span>
            <h1>Klassif<span className="norm">.io</span></h1>
            <p>
              Web classification made easy
            </p>
            <div>
              <div className="inliner">
                <div className="btn btn-blue">Access</div>
                <div className="btn btn-orange" onClick={()=>{ goHome()}} >Info</div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            { redir }
          </div>
          <div className="col-md-3">
          </div> 
        </div>
        <canvas id="myCanvas" resize keepalive="true"></canvas>
      </div>
    )
  }
}


const mapStateToProps = (state, props) => { 
  return { 
    app: state.ra,
    user: state.user,
    isFetching: state.user.isFetching
  }
}

const mapDispatchToProps = (dispatch) => {  
  return { 
    foo: (s,d) => {
      dispatch(activation(s,d))
    },
    updatepass: (s,d,p) => {
      dispatch(resetpass(s,d,p))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Redir)