import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Line } from 'react-chartjs-2';
import $ from "jquery";

import { userStats, updateStatsDays } from '../actions/user'

const debug = true
const statsOptions = [180, 90, 30, 7, 3]

class Stats extends React.Component {

  static propTypes = {
  }

  constructor(props){
    super(props);
    this.state = {
      selected: [],
      tsStatsAt: 0,
      stats: null,
      statsDaysIndex: 2
    }
  }

  componentDidMount() {
    let state = this.state
    if(debug)
      console.log("Stats", this.props)

    if(this.props.ra.sid)
      if( state.tsStatsAt < Date.now() - (1000 * 30) ) {
        state.tsStatsAt = Date.now()
        this.props.requestUserStats()
        this.setState(state)
      }

    state.statsDaysIndex = statsOptions.indexOf(this.props.user.statsDays)
    this.setState(state)
  }

  componentWillReceiveProps(next){
    var state = this.state, requested = false
    if(!this.props.ra.sid && next.ra.sid ) {
      this.props.requestUserStats();
      requested = true
    }

    if(this.props.user.stats !== next.user.stats) {
      console.log("--- stats arrive ---")
      state.stats = next.user.stats;
      this.setState(state)
    }

    if(this.props.ra.sid)
      if(state.tsStatsAt < Date.now() - (1000 * 30) && !requested) {
        state.tsStatsAt = Date.now()
        this.props.requestUserStats()
        this.setState(state)
      }
  }

  selectKey(e){
    let state = this.state, attr
    if( state.selected.indexOf($(e.target).attr('data-sel')) === -1 ) {
      state.selected.push($(e.target).attr('data-sel'))
    } else {
      attr = $(e.target).attr('data-sel');
      state.selected=state.selected.filter(e => e !== attr);
    }
    this.setState(state);
  }

  updateStatsDays() {
    var state = this.state
    state.statsDaysIndex = (state.statsDaysIndex + 1) % statsOptions.length
    this.setState(state)

    this.props.updateStatsDays(statsOptions[state.statsDaysIndex])
    this.props.requestUserStats()
  }

  setStatsDays(e) {
    var value = parseInt($(e.target).html(), 10)
    var state = this.state
    state.statsDaysIndex = statsOptions.indexOf(value)
    this.setState(state)

    this.props.updateStatsDays(statsOptions[state.statsDaysIndex])
    this.props.requestUserStats()
  }

  getUsageChart(){
    // a =['2018-12-03','2018-12-04','2018-12-08'],
    // b = [43,3,5],
    var a = [], b = [], totalHits

    if(!this.state.stats)
      return null;

    for(var i = 0;i<this.state.stats.length;i++){
      a.push(this.state.stats[i].date)
      b.push(this.state.stats[i].hits)
    }

    totalHits = b.reduce((a, b) => a + b)
    
    return {
      labels: a,
      datasets: [{
        label: `Usage (${totalHits})`,
        backgroundColor: 'transparent',
        borderColor: 'rgba(60, 141, 188, 0.9)',
        borderWidth: 1,
        data: b,
      }]
    }
  }

  render() { 
    // const colors = ['#F39C13', '#ffa700', '#ffb72d', '#ffdb96']
    const data = this.getUsageChart()
    var chart;

    if(data) {
      chart = (
            <Line data={data}
              options={{
                legend:  { labels:{} },
                elements:{ point:{ radius: 1 } },
                scales: {
                  xAxes: [{ gridLines: { display:false } }],
                  yAxes: [{ gridLines: { display:false } }],
                }
              }}
              height={300}
              width={700}
            />)
    } else {
      if(this.props.user.user && this.props.user.user.hits === 0){
        chart = (<p className="no-keys">
          <i className="fa fa-info-circle top"></i>
            Nothing to show<br />
            <br />
            Stats show the usage of your keys as per day number of requests.<br />
            Whenever you use a key, it will log activity in this char.
          </p>)
      } else {
        chart = (<div className="text fill">Loading...</div>)
      }
    }

    return(
      <div className="thinner stats">
        <div className="col-md-12">
          <h3>Your keys</h3>
        </div>
        { this.props.keys.keys.length > 0 && <div className="col-md-8">
          <div className="inliner keybox header">
            <div className="elem kid">Key <span className="small">Truncated</span></div>
            <div className="elem ktext">Created</div>
            <div className="elem ktext">Last used</div>
            <div className="elem kunit">Hits</div>
            <div className="elem del"></div>
          </div>
          { this.props.keys.keys.map((e,i)=>{
              return(<div key={i} className="inliner keybox">
                <div className="elem pid">{e.key.substring(0,32)}</div>
                <div className="elem ktext">{e.created.substring(0,16)}</div>
                <div className="elem ktext">{e.lastused ? e.lastused.substring(0,16) : 'Never'}</div>
                <div className="elem kunit">{e.timesused}</div>
                <div className="elem del sel"><i className={ this.state.selected.indexOf(e.key.substring(0,32))===-1 ? "fa fa-square-o" : "fa fa-square" } data-sel={e.key.substring(0,32)} onClick={(e)=>{ this.selectKey(e) }}></i></div>
              </div>)
          }) }
        </div> }
        { (this.props.keys.keys.length === 0 && !this.props.user.hasNoKeys) && <div className="col-md-8">
          <div className="inliner keybox header">
            <div className="elem kid">Key <span className="small">Truncated</span></div>
            <div className="elem ktext">Created</div>
            <div className="elem ktext">Last used</div>
            <div className="elem kunit">Hits</div>
            <div className="elem del"></div>
          </div>
          { [0,1].map((e, i)=>{ 
              return(<div key={i}  className="inliner keybox">
                <div className="elem kid"><span className="fill">&nbsp;</span></div>
                <div className="elem ktext"><span className="fill">&nbsp;</span></div>
                <div className="elem ktext"><span className="fill">&nbsp;</span></div>
                <div className="elem kunit"><span className="fill">&nbsp;</span></div>
                <div className="elem del"></div>
                </div>
              )
            })
          }
        </div> }

        { this.props.user.user && this.props.user.user.hits === 0 && this.props.user.hasNoKeys && <div className="col-md-8">
          <div className="inliner keybox header">
            <div className="elem kid">Key <span className="small">Truncated</span></div>
            <div className="elem ktext">Created</div>
            <div className="elem ktext">Last used</div>
            <div className="elem kunit">Hits</div>
            <div className="elem del"></div>
          </div>
          { [0].map((e, i)=>{ 
              return(<div key={i}  className="inliner no-keys">
                <i className="fa fa-info-circle top"></i>
                <div className="elem">
                  <p className="">No keys to show<br />There are still no keys in your account.</p>
                </div>
              </div>
              )
            })
          }
        </div> }
        <div className="col-md-4">
          <h4>Key hits</h4>
          <p>See the usage on your available keys<br/> 
          You can select a key to se its concrete usage, few, <br/>
          or none, to see the general usage.
          </p>

          <hr />
          <p>
          Read more on how to use keys <Link to="/dashboard/doc">here</Link>
          </p>

        </div>
        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-12">
          
        </div>
        <div className="col-md-8">
          <h3>Stats</h3>
          <div className="filters">
            <ul>
              <li><span>Last</span></li>
              {/* <li><span className="button" onClick={() => this.updateStatsDays()}>{statsOptions[this.state.statsDaysIndex]}</span></li> */}
              <li>{ statsOptions.map((n) => <span className={n === statsOptions[this.state.statsDaysIndex] ? "tab current" : "tab"} onClick={(e) => this.setStatsDays(e)}>{n}</span>) }</li>
              <li><span>days</span></li>
            </ul>
          </div>
          { chart }
        </div>
      </div>
      
    )
  }
}

const mapStateToProps = (state, props) => {
  return { 
    user: state.user,
    ra: state.ra,
    keys: state.keys
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestUserStats: () => {
      dispatch(userStats())
    },
    updateStatsDays: (d) => {
      dispatch(updateStatsDays(d))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats)