import React from   'react';
import { Route, Switch } from 'react-router-dom';

import Welcome from '../containers/Welcome'
import Redir from '../containers/Redir'
import Login from '../components/Login'
import Register from '../components/Register'
import Reset from '../components/Reset'
import About from '../components/About'
import Contact from '../components/Contact'

const Routes = (props) => { 
	return(
	  <Switch>
	    <Route exact path="/" component={Welcome} />
	    <Route exact path="/login" component={Login} />
	    <Route exact path="/reset" component={Reset} />
	    <Route exact path="/register" component={Register} />
	    <Route exact path="/about" component={About} />
	    <Route exact path="/payment/execute" component={Welcome} />
	    <Route exact path="/dashboard" component={Welcome} />
	    <Route exact path="/contact" component={Contact} />
	    <Route path="/dashboard/activate/:sig" component={Redir} />
	    <Route path="/dashboard/reset/:sig" component={Redir} />
	    <Route path="/dashboard/:section" component={Welcome} />
	  </Switch>
	);
}

export default Routes;