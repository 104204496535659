import React from 'react';
import { Link } from 'react-router-dom'

import { copyTextToClipboard } from '../actions/lib'
import $ from "jquery";

const debug = true
const httpCodesInfo = 'https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html'


class About extends React.Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  componentDidMount() {
    var str = window.location.href.substring(window.location.href.indexOf('#') + 1) || ""
    var elem = $("*[name='" + str + "']")[0];
    if(!elem)
      return
    
    if(debug) {
      console.log('Scrolling', elem, elem.offsetTop);
    }
    $('html, body').animate({ scrollTop: $(elem).offset().top }, 1800); 
  }

  componentDidUpdate(){
    var str = window.location.href.substring(window.location.href.indexOf('#') + 1) || ""
    var elem = $( "*[name='" + str.toLowerCase() + "']")[0];
    if(!elem)
      return
    $('html, body').animate({ scrollTop: $(elem).offset().top }, 1800); 
  }

  copyAction(e) {
    var node = $(e.target), p = node.parent()
    copyTextToClipboard(node.attr('data-version'))
    p.append('<span class="small">copied</span>')
    setTimeout(() => {
      p.find('span.small').remove()
    }, 3000)
  }

  render() { 
    return(
      <div className="welcome about">
        <div className="thinner">
        <div className="col-md-12 site-info">
          <Link to="/dashboard/keys" style={{textDecoration: 'none'}}><h1 name="klassif.io">Klassif.io</h1></Link>
            <p>
              Klassif.io has been built to easily get a taxonimy or classification of any free accesible internet resource.
            </p>
            <br />
          <h3 name="usage">Usage</h3>
            <p>
              It can robustly provide a set of categories from the content hosted in a url. The classifier is designed to categorize text content, not the website itself. 
              This behavior, would provide wide different results if loads the index page of a newspaper, of the site of an article of that newspaper. It can provide the 
              category for each article in that newspaper, not really the caetgory of the newspaper itself.
            </p>
            <p>
              The system works with <b>English</b> language websites. Requests on urls whose content main vocabulary is not English will give no results.<br />
              The internet resource must contain a reasonable number of terms. It is recommended that the resource is rich in text content. Well formatted html code makes the resources to be more "understandable" for the classifier,
              the website code and common terms are not taking into consideration to build the models for classification.
            </p>
          <h3 name="keys">Keys</h3>
           <p>
              Requests to the api service are signed.
              The user dashboard provides a simple way to manage these keys through the application. The user can keep track of the generated keys,
              hits (number of requests), usage stats on each key.<br />
              Create a key requires just one click, a single user can create up to 100 authorization keys. Keys do not expirce and can be easily
              created or removed
            </p>
            <p>
              <b className="grey">Pricing</b><br />
              <span className="">
                The minimum ammount of requets any user can purchase at a time is 100, the maximum is 1 million.<br />
                The price below 10K keys is 0.01€. This price get dramatically reduced increasing the number of requests up to 1M where the costs goes down to 0.001€
              </span>
            </p>
            <p>
              <b className="grey">Hits</b><br />
              <span className="">
                A hit is a request send to the classifier service<br />
                We consider an <b>invalid hit</b> a request sent to the classifier containing a valid source url, but:
                <span className="indent">
                  The classifier fails to fetch the content in the sent url param <code>400 Bad Request</code><br />
                  The request is cancelled due to an internal error <code>500 Server Error</code><br />
                  The request is cancelled due to inactivity (generally a <code>504 Gateway Timeout</code> or a <code>503 Service Unavailable</code>)<br />
                  The request is Unauthorized. A <code>401 Unauthorized</code>, always below the maximum number of consecutive unauthorized accesses. After that the ip will be banned<br />
                </span>
                You can read more about HTTP codes in the following <a rel="noopener noreferrer" target="_blank" href={httpCodesInfo}>RFC 2616</a> document. 
              </span>
            </p>
          <h3 name="limits">Limits</h3>
            <p>
              The system can be used under a reasoble usage policy. Several limitations apply on how the API behaves with incoming connections. 
              The maximun requests/second throttle is set in 150 req/s. This number is relative as the system will handle a set of incoming requests, when it reach the queue
              limit, it will reject any connection will the waiting queue is leveraged.
              In order to build your client, have in mind that the frequency of your requests must be tighten to a relative maximum of req/s. We recommend you to set this limits on your side. 
              Before being rejected for queueing reasons, every request is subjected to the throttling limits.<br />
            </p>
            <p>
              <b className="grey">Temporal ban</b><br />
              Any ip address being banned will be so for the next 24 hours. If the same ip address is banned twice and you abolutely need to 
              enable it, you can <Link to="/contact">contact us</Link> to let us know the reason.
            </p>
            <p>
              <b className="grey">Ban policy</b><br />
              The system will temporally ban any client ip address if:
              <ul className="flat indent">
                <li>
                  <span>the Ip address is failing authentication a repeated number of times</span><br />
                  <small className="indent">This is a bad case, as the Ip will be banned for a undetermined period of time.</small>
                </li>
                <li>
                  <span>the Ip address exceeds 1 req/s on the same domain</span><br />
                  <small className="indent">The Ip address will be banned for 60 minutes.</small>
                </li>
                <li>
                  <span>the Ip address exceeeds the 60 req/s limit</span><br />
                  <small className="indent">The Ip address will be banned for 30 minutes.</small>
                </li>
                <li>
                  <span>the Ip address sends invalid urls a repeated number of times</span><br />
                  <small className="indent">The Ip address will be banned for 24 hours.</small>
                </li>
              </ul>
             </p>
          <h1 name="classification">Classification</h1>
          <h3 name="brief">Brief</h3>
            <p>
              Classification is achieved using the orchestration of several components.<br />
              It is mainly supported by a dense classifier model built using a large set of internet 
              resources. The classification taxonomy has been directly extracted from the website dataset, as a result of
              a non supervised clustering process.
            </p>
          <h3 name="analysis">Analysis</h3>
            <p>
              The classifier follows a supervised learning model, the datasets obtained out of the processed text collections, are known beforehand, and 
              we vectorize them differently following a stict methodology on our request.<br />
              The classifier research reached a stable version of the classifier model on <code>0.5.3</code>, this is the current version running on our public web service.<br />
              Our current experimental version is on <code>0.6.1</code>, we work to continously improve the quality of the classifier and improve the performance of the whole process.
            </p>
            <p>
              <b className="grey">Available versions</b><br />
              <ul className="flat indent">
                <li>
                  <span className="indent"><code>0.5.3</code></span> <span className="elem cp"><i data-version="0.5.3" onClick={(e) => this.copyAction(e)} className="fa fa-clipboard"></i></span> <br />
                  <span className="indent">Stable version. Focused on terms, minifies the contextual meaning.</span>
                </li>
                <li>
                  <span className="indent"><code>0.6.1</code></span> <span className="elem cp"><i data-version="0.6.1" onClick={(e) => this.copyAction(e)} className="fa fa-clipboard"></i></span><br />
                  <span className="indent">Unstable version. Focused on short phrases, maximizes the contextual meaning.</span>
                </li>
              </ul>
            </p>
          <h3 name="sources">Sources</h3>
            <p>
              We filter the provided url for the classification request. There are a set of governamental organizations under <code>.gov</code> which are restricted.
              We also forbid the access to security compromised websites and to the tlds of some countries.
              The classifier only accepts English language sources and any request to other languages will be counted. It is up to the user 
              to send the classififer a proper url.
            </p>
          <h1 name="web-service">Web Service</h1>
          <h3 name="site">Site</h3>
            <p>
              The web application serves as a authentication key manager. These keys can be created, listed and deleted.
              The web application serves to track the usage made on keys as well, by providing visual charts to show the usage in the Stats section.
            </p>
          <h3 name="users">Users</h3>
            <p>
              In order to access the Service, API Providers and API Consumers must register with Klassif.io by completing the registration form provided in the web application.
              The user is responsible for safeguarding the passwords you use to access the Service and agree to be fully responsible for activities or transactions that relate to your Klassif.io account or password.<br/>
              The user must notify Klassif.io immediately if he/she notices an unauthorized use of his/her Klassif.io account or password.
            </p>
          <h3 name="purchases">Purchases</h3>
            <p>
              The user can purchase requests which will be the credit used each time the classifier is hit with one of your keys.
              Payments to purchase this requests are managed from the user profile in the application as well.
            </p>
          <h1 name="terms">Terms</h1>
          <h3 name="">Service Terms</h3>
            <p>
              By registering to use the Service, accessing the Service or providing access to the API, you agree and acknowledge that you have read all of the terms
              and conditions of these Terms, you understand all of the terms and conditions of these Terms, and you agree to be legally bound by all of the 
              terms and conditions of these Terms.
            </p>
            <p>
              You agree to (a) provide accurate, current, and complete information as may be prompted by the registration forms via the web application (“Registration Data”),
              (b) maintain the security of your Klassif.io account password, (c) maintain and promptly update the Registration Data, and any other information you provide to Klassif.io,
              to keep it accurate, current, and complete and (d) accept all risks of unauthorized access to the Registration Data and any other information you provide to Klassif.io.<br/>
              The user is responsible for safeguarding the passwords you use to access the Service and agree to be fully responsible for activities or transactions that relate to your Klassif.io account or password.<br/>
              The user must notify Klassif.io immediately if he/she notices an unauthorized use of his/her Klassif.io account or password.
            </p>
          <h1 name="privacy">Privacy</h1>
          <h3 name="privacy">Privacy Policy</h3>
            <p>
              Data Privacy is important for us (Klassif.io). This Privacy Notice explains how information is collected, used and disclosed by Klassif.io and applies to 
              information collected when you interact with the Klassif.io via our web application or use the API service of the Klassif.io classifier.
              We respect the privacy rights of individuals interacting with us, the user community and enterprise customers.
              Recognizing the importance of protecting, managing, and controlling the information we collect about you in accordance with the regulatory 
              obligations associated with that information is of paramount importance to us. As such, please read the following carefully to understand how we will collect, 
              use, and maintain your personal information.
              This Privacy Notice also describes your choices regarding use, access, and correction of your personal information.
            </p>
            <p>
              <b>Information Collected</b><br />
              We may collect certain user information (including personal information and/or sensitive personal information) in the following ways:
            </p>
            <p>
              <b>Information you provide to us</b><br />
              <span className="indent">
                We collect information you provide directly to us including then you visit our website, register and use the classifier. 
                For example, we collect information when you interact with Klassif.io, register as a Klassif.io user, create or modify your profile, 
                interact with your Klassif.io online account or access and use the classifier.
                The type of information we may collect directly from you includes your name, user name, email address and transactional data relating to your use of the Klassif.io service.
                Please keep in mind that the information you choose to provide in your Klassif.io profile may also be considered as PII or be 
                considered as sensitive information that can identify you as an individual.
              </span>
            </p>
            <p className="indent">
              <b className="grey">Usage information</b><br />
              <span className="indent">
                We monitor user activity connected with the Klassif.io service, frequence of usage, and information from the resources sent to us.
              </span><br />
              <b className="grey">Login information</b><br />
              <span className="indent">
                We log information about your activity when you access and use the Klassif.io service including your Internet Protocol (IP) address,
                access times, browser types and language and Internet Service Provider (ISP)
              </span><br />
              <b className="grey">Device information</b><br />
              <span className="indent">
                We collect information about the device that you log into the service from, including the hardware model, operating system and version, and the unique device identifiers
              </span><br />
            </p>
            <p>
              <b>Use of Information</b><br />
              <span className="indent">
                Provide, operate, maintain and improve the Klassif.io service<br />
                Enabling you to access and use the Klassif.io service<br />
                Sending you technical notices, security alerts or support<br />
                Provide and deliver the services and features you request, process and complete transactions, and send you related information, including purchase confirmations and invoices<br />
                Respond to your comments, questions, and requests and provide customer service and support<br />
                For other purposes about which we will provide you with prior notice as described in the “Changes to This Notice” section<br />
              </span><br />
            </p>
          <h3 name="disclose">Disclose</h3>
            <p>
              Your use of the service is at your sole risk.<br />
              The service is provided on an “as is” and “as available” basis. Klassif.io disclaims all warranties and representations 
              (express or implied, oral or written) with respect to these terms,
              the web service, the web application (including any information and content made available via the application and the Klassif.io materials),
              whether alleged to arise by operation of law, by reason of custom or usage in the trade, by course of dealing or otherwise,
              including any warranties of merchantability, fitness for any purpose, non-infringement, and condition of title.<br />
              To the fullest extent permitted by applicable law, Klassif.io does not warrant, and disclaims all liability for 
              (a) the completeness, accuracy, availability, timeliness, security, or reliability of the service, 
              any user content, the web application (including any information or content made available via the web application); 
              (b) any harm to your computer system, loss of data, or other harm that results from your access to or use of the 
              service and any api made available via the service; 
              (c) the deletion of, or the failure to store or transmit, any user content and other communications maintained by 
              the service; and (d) whether the service will meet your requirements or be available on an uninterrupted, 
              secure, or error-free basis.
            </p>
            <br />
        </div>  
        </div>  
      </div>
    )
  }
}

export default About
