import React from 'react'
import thunk from 'redux-thunk'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { save, load } from "redux-localstorage-simple"

import Reducer from './reducers'
import App from './containers/App'

const middleware = [ thunk ]

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger())
}

const createStoreWithMiddleware = applyMiddleware(
  save({ namespace: "klassifier" }), ...middleware //thunk, logger // Saving done here
)(createStore)


const store = createStoreWithMiddleware(
  Reducer, load({ namespace: "klassifier" })  
)

export { store }

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="*" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)
