import React from 'react';
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { loginUser } from '../actions/user'
import { validateEmail, goHome } from '../actions/lib'

import moment from 'moment'
import $ from 'jquery'

const debug = true


class Login extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    isFetching: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {
      redirect:false,
      to_date:moment(),
      from_date:moment().subtract(3, "days"),
      email_error:null,
      connect_error:null,
      message:null
    }
  }

  componentDidMount(){

    $('header').hide();
    $('.site-footer').addClass('blue');

    var event = new Event('start-waves');
    if(event.type !== 'start-waves') {
      setTimeout(function(){
        var event = new CustomEvent('start-waves',  {"bubbles":true, "cancelable":false} );
        document.dispatchEvent(event);
      },10)
    } else {
      document.dispatchEvent(event);
    }
    
  }

  componentWillUnmount(){
    $('.site-footer').removeClass('blue');
  }

  componentWillMount(){
    let arr, email, state = this.state

    if(this.props.location.search) {
      arr = this.props.location.search.replace('?','').split('&');
      email = arr[0].split('=')[1]
      console.log("email >>> ",email)
      state.message = email
      this.setState(state)

      // TODO: ?
      this.props.history.push("/login");
    }
  }

  componentWillReceiveProps(next){ 
    /**
     * Redirect is available in this case
     */
    console.log(this.props, next);

  }

  login(){
    let email=$(this.refs.mail).val()
    let pass=$(this.refs.pass).val()
    let valid =validateEmail(email);
    let state = this.state

    if(debug)
      console.log(valid, email)
    if(!valid) {
      state.email_error = 'Not a valid email...'
      $(this.refs.mail).val("")
      this.setState(state)
    } else {
      state.email_error = null
      this.setState(state)
      this.props.loginuser(valid, pass)
    }
  }

  render() {
    if(debug)
      console.log(this.state.email_error)

    if(this.state.redirect)
      window.location = this.props.redir

    if(this.props.isLogged)
      return( <Redirect to="/dashboard/keys"/> )

    return ( 
      <div className="login cls">
        <div className="center">
          <div className="col-md-3">
          </div> 
          <div className="col-md-3 headline">
            <span className="text gt">Welcome to</span>
            <h1>Klassif<span className="norm">.io</span></h1>
            <p>Web classification made easy</p>
            <div>
              <div className="inliner">
                <div className="btn btn-blue">Access</div>
                <div className="btn btn-orange" onClick={()=>{ goHome()}} >Info</div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <span className="text"> {this.state.message} </span>
            { this.state.message && <hr /> }
            <div className="text">Enter your email...</div>
            <div className={this.state.email_error ? "form-group has-error" : "form-group" }>
              <input type="text" className="form-control" ref="mail" placeholder={this.state.email_error ? this.state.email_error : "Your email..." } />
            </div>
            <div className="text">Enter your password</div>
            <input type="password" className="form-control" ref="pass" placeholder="Password..." />
            { this.props.user.hasErrors && <div className="login-errors">
              <div className="text fat">Couldn't log you in</div>
              <div className="text"><small>{this.props.user.errors[0]}</small></div>
            </div>
            }
            <hr />
            <div className="row">
              <div className="col-md-4" style={{textAlign: "center", paddingTop: "5px"}}>
                { this.props.app.connected && <button className="btn btn-default" onClick={ () => this.login() }>Login</button> }
                { !this.props.app.connected && <button className="btn btn-default disabled" onClick={ () => {} }>Login</button> }
                { !this.props.app.connected && <div className="text small">connecting</div> }
                { this.props.isFetching && <div className="loading-white"></div> }
              </div>
              <div className="col-md-8">
                <span> or <Link to="/register" className="wl">register</Link> or <Link to="/reset" className="wl">reset</Link> password</span>
              </div>
            </div>
            <hr />
          </div>
          <div className="col-md-3">
          </div> 
        </div>
        <canvas id="myCanvas"></canvas>
      </div>
    );
  }
}


const mapStateToProps = (state, props) => { 
  console.log(state);
  return { 
    user: state.user,
    app: state.ra,
    isFetching: state.user.isFetching,
    isLogged: state.user.isLogged
  }
}

const mapDispatchToProps = (dispatch) => {  
  return { 
    loginuser: (e, p) => {
      dispatch(loginUser(e,p))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login)