import {
  GET_KEYS_REQUEST,
  GET_KEYS_SUCCESS,
  GET_KEYS_FAIL,
  CREATE_KEY_REQUEST,
  CREATE_KEY_SUCCESS,
  CREATE_KEY_FAIL,
  DELETE_KEY_REQUEST,
  DELETE_KEY_SUCCESS,
  DELETE_KEY_FAIL
} from '../actions/keys'

import { INIT } from '../actions/app'

const keys = (state = {
  isFetching: false,
  isCreating: false,
  isDeleting: false,
  hasErrors: false,
  errors: [],
  keys: [],
  ts: 0
}, action) => {
  switch(action.type) {
    case INIT: 
      return {
        isFetching: false,
        isCreating: false,
        isDeleting: false,
        hasErrors: false,
        errors: [],
        keys: state.keys,
        ts: state.ts
      }
    case DELETE_KEY_REQUEST:
      return {
        ...state,
        isDeleting: true,
        hasErrors: false,
        errors: [],
        ts: action.ts
      }
    case CREATE_KEY_REQUEST:
      return {
        ...state,
        isCreating: true,
        hasErrors: false,
        errors: [],
        ts: action.ts
      }
    case GET_KEYS_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasErrors: false,
        errors: [],
        ts: action.ts
      }
    case GET_KEYS_SUCCESS:
      console.log(action.data.keys)
      return {
        ...state,
        isFetching: false,
        keys: action.data.keys,
        ts: Date.now()
      }
    case GET_KEYS_FAIL:
      return {
        ...state,
        isFetching: false,
        errors: [action.error],
        hasErrors: true
      }
    case CREATE_KEY_SUCCESS:
      return {
        ...state,
        isCreating: false
      }
    case CREATE_KEY_FAIL:
      return {
        ...state,
        isCreating: false,
        errors: [action.error],
        hasErrors: true
      }
    case DELETE_KEY_SUCCESS:
      var k, keys = state.keys
      for (var i = 0; i < keys.length; i++) {
        k = keys.shift()
        if (k.key !== action.data)
          keys.push(k)
      }
      return {
        ...state,
        keys: keys,
        isDeleting: false
      }
    case DELETE_KEY_FAIL:
      return {
        ...state,
        isDeleting: false,
        errors: [action.error],
        hasErrors: true
      }

    default: 
      return state
  }
}

export default keys
