/**
 * 
 */

import $ from 'jquery'
import md5 from 'md5'
import { EP } from './user'

export const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST'
export const CONTACT_REQUEST_SUCCESS = 'CONTACT_REQUEST_SUCCESS'
export const CONTACT_REQUEST_FAIL = 'CONTACT_REQUEST_FAIL'


const sendContactRequest = () => {
  return {
    type: SEND_CONTACT_REQUEST,
    ts: Date.now()
  }
}

const contactSuccess = (a) => {
  return {
    type:CONTACT_REQUEST_SUCCESS,
    data: a, 
    ts: Date.now()
  }
}

const contactFail = (a) => {
  return {
    type:CONTACT_REQUEST_FAIL,
    data: a, 
    ts: Date.now()
  }
}

export const sendContact = (topic, brief, message) => (dispatch, getState) => {

  var state = getState()
  console.log(state)

  dispatch(sendContactRequest())

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(contactSuccess(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch(contactFail(res))
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+ state.user.csfr); // hash
  }

  var ts = Date.now() / 1000
  var data = {
    message: message,
    topic: topic,
    brief: brief,
    sign: state.ra.sid,
    song: md5(state.ra.sid+ts),
    ts: ts
  }

  $.ajax(
    {
      url: EP + '/contact',
      method: 'POST',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data
  });
}
