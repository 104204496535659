import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'

import { requestPayments } from '../actions/app'
import { logoutUser, userData } from '../actions/user'
import { copyTextToClipboard } from '../actions/lib'

import $ from 'jquery'
import moment from 'moment'

const debug = true

class Payments extends React.Component {

  static propTypes = {
  }

  constructor(props){
    super(props);
    this.state = {
      tsPaymentsAt: 0
    }
  }

  componentWillMount() {
    if(debug)
      console.log("Payments", this.props);

    let state = this.state
    if(this.props.ra.sid)
      if(this.state.tsPaymentsAt < Date.now() - (1000 * 30)) {
        state.tsPaymentsAt = Date.now()
        this.setState(state)
      }
  }

  componentDidMount() {
    if(this.props.ra.sid) {
      this.props.requestPayments()
      this.props.requestUserData()
    }
  }

  getPaymentsHistory(){
    var payset = (this.props.user.payments) ? this.props.user.payments : [],
      a=[],b=[],c=[],dt,dts,sorted,agg={}

    if(payset.length === 0)
      return null;

    // sort payments from older to new (opposite query db)
    sorted=payset.sort((a, b)=>{
      if(a.created < b.created) return -1;
      if(a.created > b.created) return 1;
      return 0;
    })

    // aggegation by date str: "MMM DD"
    
    for(var i = 0;i<sorted.length;i++){
      dt=sorted[i].created
      dts=moment(dt).format("MMM DD")
      if(Object.keys(agg).indexOf(dts) === -1) {
        a.push(dts)
        agg[dts]={hits:sorted[i].hits, price:sorted[i].price }
      } else {
        agg[dts].hits+=sorted[i].hits 
        agg[dts].price+=sorted[i].price
      }
    }
    for(i=0;i<a.length;i++){
      b.push(agg[a[i]].hits)
      c.push(agg[a[i]].price)
    }
    
    return {
      labels: a,
      datasets: [
        {
          label: "Hits",
          yAxisID: 'A',
          backgroundColor: 'transparent',
          borderColor: 'rgba(60, 150, 190, 0.9)',
          borderWidth: 1,
          data: b,
        },
        {
          label: "Price (€)",
          yAxisID: 'B',
          backgroundColor: 'transparent',
          borderColor: '#4caf50',
          borderWidth: 1,
          data: c,
        }
      ]
    }
  }

  logoutUser(){
    this.props.logout()
  }

  copyAction(e) {
    copyTextToClipboard(this.props.user.csfr)
    $('.cp').append('<span class="small">copied</span>')
    setTimeout(() => {
      $('.cp').find('span.small').remove()
    }, 3000)
  }

  render() {
    if(debug)
      console.log("Payments render >>>", this.props);
    const user = this.props.user.details;
    const payments = this.props.user.payments;
    const data = this.getPaymentsHistory()

    if(user)
      console.log(user, Object.keys(user))
    
    var chart;
    if(data){
      chart = (<div>
        History of recent payments<br />
        <small>(Hits purchased and associated price)</small>
        <Bar data={data}
          options={{
            legend:  { labels:{} },
            elements:{ point:{ radius: 1 } },
            scales: {
              xAxes: [ { gridLines: { display:false } }],
              yAxes: [
                { id: 'A', type: 'linear', position: 'left',  ticks: { max:100000, min:0 }, gridLines: { display:false } },
                { id: 'B', type: 'linear', position: 'right', ticks: { max:250,    min:0 }, gridLines: { display:false } }
              ],
            }
          }}
          height={300}
          width={700}
        />
      </div>)
    } else {
      if(this.props.keys.keys.length === 0 && this.props.user.hasNoKeys){
        chart = (<p className="no-keys">
          <i className="fa fa-info-circle top"></i>
            Empty history<br />
            <br />
            The history of payments show payments and requests purchased since you signed up.<br />
            Whenever you make a requests purchase, the action will be logged in this chart.
          </p>)
      } else {
        chart = (<div className="text fill">Loading...</div>)
      }
    }
    return(
      <div className="thinner">
        <div className="col-md-12"></div>

        { user && <div className="col-md-4">
          <h3>Details</h3>
          <div className="inliner keybox">
            <div className="elem knum">Name</div><div className="elem">{user.name} {user.last_name}</div>
          </div>
          <div className="inliner keybox">
            <div className="elem knum">Email</div><div className="elem">{user.email}</div>
          </div>
          <div className="inliner keybox">
            <div className="elem knum">Created</div><div className="elem">{moment(user.created).fromNow()}</div>
          </div>
          <div className="inliner keybox pid">
            <div className="elem knum">Public Id</div><div className="elem">{this.props.user.csfr.slice(0, 10)} <span className="small">truncated</span></div><div className="elem cp"><i style={{}} onClick={(e) => this.copyAction(e)} className="fa fa-clipboard"></i></div>
          </div>
          { user.lastvisit && <div className="inliner keybox">
            <div className="elem knum">Last Visit</div><div className="elem">{moment(user.lastvisit).fromNow()}</div>
          </div> }
        </div> }

        { !user && <div className="col-md-4">
          <h3>Details</h3>
          <div className="inliner keybox">
            <div className="elem knum"><span className="fill">&nbsp;</span></div>
          </div>
          <div className="inliner keybox">
            <div className="elem knum"><span className="fill">&nbsp;</span></div>
          </div>
          <div className="keybox">
            <div className="elem"><span className="fill">&nbsp;</span></div>
          </div>
          <div className="keybox">
            <div className="elem"><span className="fill">&nbsp;</span></div>
          </div>
        </div> }
        { user && <div className="col-md-4">
          <h3>Requests</h3>
          <div className="keybox inliner">
            <div className="text big">{user.hits}</div><div className="elem">total hits</div>
          </div>
          <div className="keybox inliner">
            <div className="text big">{user.requests}</div><div className="elem">requests left</div>
          </div>
        </div> }
        { user && <div className="col-md-4">
          <figure className="chart" data-percent={`${parseInt((user.requests * 100) / user.hits, 10) - parseInt((user.requests * 100) / user.hits, 10) % 10}`}>
            <svg width="100" height="100">
              <circle className="outer" cx="45" cy="45" r="35" transform="rotate(-90, 45, 45)"/>
            </svg>
          </figure>
          <p><span style={{fontSize: "1.6em"}}>{user.hits} / {user.requests}</span> requests ({parseInt((user.requests * 100) / user.hits, 10)}%)</p>
        </div> }
        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-12">
          <h3>Your payments</h3>
        </div>
        { payments && payments.length > 0 && <div className="col-md-8 payment-list">
          <div className="inliner keybox header">
            <div className="elem kid">Payment Id<span className="small"></span></div>
            <div className="elem ktext">Created</div>
            <div className="elem knum">Hits</div>
            <div className="elem kpvp">Price</div>
            <div className="elem kunit">Approved</div>
            <div className="elem del"></div>
          </div>
          { payments && payments.length > 0 && this.props.user.payments.map((e, i)=>{
            return(
              <div key={i} className="inliner keybox">
                <div className="elem pid">{e.payment_id}</div>
                <div className="elem ktext">{e.created.substring(0,16)}</div>
                <div className="elem knum">{e.hits}</div>
                <div className="elem kpvp">{e.price} €</div>
                <div className="elem kunit">{e.approved ? 'YES':'NO'}</div>
              </div>)
          })}
          </div>
        }
        { (!payments || payments.length === 0) && <div className="col-md-8 payment-list">
          <div className="inliner keybox header">
            <div className="elem kid">Payment Id<span className="small"></span></div>
            <div className="elem ktext">Created</div>
            <div className="elem knum">Hits</div>
            <div className="elem kpvp">Price</div>
            <div className="elem kunit">Approved</div>
            <div className="elem del"></div>
          </div>
          { [0,1].map((e, i)=>{
            return(
              <div key={i} className="inliner keybox">
                <div className="elem pid"><span className="fill">&nbsp;</span></div>
                <div className="elem ktext"><span className="fill">&nbsp;</span></div>
                <div className="elem knum"><span className="fill">&nbsp;</span></div>
                <div className="elem kpvp"><span className="fill">&nbsp;</span></div>
                <div className="elem kunit"><span className="fill">&nbsp;</span></div>
              </div>)
          })}
          </div>
        }
        { (!payments || payments.length === 0) && <div className="col-md-8">
          <div className="inliner keybox header">
            <div className="elem kid">Key <span className="small">Truncated</span></div>
            <div className="elem ktext">Created</div>
            <div className="elem ktext">Last used</div>
            <div className="elem kunit">Hits</div>
            <div className="elem kunit">Version</div>
            <div className="elem del"></div>
          </div>
          { [0].map((e, i)=>{ 
              return(<div key={i}  className="inliner no-keys">
                <i className="fa fa-info-circle top"></i>
                <div className="elem">
                  <p className="">No payments to show</p>
                  <p className="">Any time you purchase requests, a payment element will be generated and listed here.</p>
                </div>
              </div>
              )
            })
          }
        </div> }
        <div className="col-md-4">
          <h4>Payments</h4>
          <p>
            See the payments since you signed up<br/> 
          </p>
            <hr />
          <p>
            If you would need special features for your project, please <Link to="/contact">contact us.</Link>
          </p>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-12">
          
        </div>
        <div className="col-md-8">
          <h3>Stats</h3>
          
          { chart }
        </div>
        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-12 acc">
          <h3>Account actions</h3>
          <p>
            Close your session and logout<br/> 
          </p>
          <div className="btn btn-default" onClick={()=>{this.logoutUser()}}>Logout</div> { this.props.user.isFetching && <div className="loading"></div> }
        </div>
      </div>
      
    )
  }
}

const mapStateToProps = (state, props) => {
  return { 
    user: state.user,
    ra: state.ra,
    keys: state.keys
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestUserData: () => {
      dispatch(userData())
    },
    requestPayments: () => {
      dispatch(requestPayments())
    },
    logout: () => {
      dispatch(logoutUser())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payments)
