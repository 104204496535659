import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Routes from '../routes'
import { setConnected, setDisconnected } from '../actions/app'
import Header from '../components/Header'
import Footer from '../components/Footer'

import { socket } from 'js/io.js'
export const IO = socket

const debug = true

class App extends Component {

  static propTypes = {
    items: PropTypes.array,
    isFetching: PropTypes.bool,
    lastUpdated: PropTypes.number,
    loggedUser: PropTypes.object,
  }

  constructor(props, context) {
    super(props)

    this.state = {
      items: [],
      isFetching: false,
      loggedUser: null,
    }
  }

  componentDidMount() {
    let copy = this
    IO.on('connect', function(s) {
      if(debug) {
        console.log(IO, IO.id, IO.sessionId, IO.json.id);
        console.log(s);
      }
      copy.props.connect(IO.id)
    });

    IO.on('reconnect', function(a, b, c) {
      if(debug) {
        console.log('reconnect', IO, IO.id, IO.sessionId, IO.json.id);
        console.log(a, b, c);
      }
      
      IO.emit('irec', {
        uuid: copy.props.user.csfr,
        reconnect: true,
        addr: a && a.handshake ? a.handshake.address : "",
        ua: a && a.request ? a.request.headers['user-agent'] : ""
      })

      copy.props.connect(IO.id)
    });

    IO.on('disconnect', function(some) {
      if(debug) {
        console.log('reconnect', IO, IO.id, IO.sessionId, IO.json.id);
        console.log(some);
      }
      copy.props.disconnect()
    });
    
    IO.on('ihsc', function(data) {
      console.log("server updated socket", IO.id, 'ihsc', data);
    });

    if(this.props.user.csfr)
      IO.emit('ih', {uuid: this.props.user.csfr});
  }

  render() {
    if(debug)
      console.log("App", this.props );

    return (
      <div className="board js-brd">
        <Header 
          user={ this.props.loggedUser } 
          islogged={ this.props.islogged }
          isConnected={ this.props.app.connected }
          path={ this.props.location.pathname }
        />
        { this.props.isFetching && <div></div> }
        <div className="app hold row">
          <Routes />
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (reducers, props) => {
  return {
    user: reducers.user,
    app: reducers.ra,
    isFetching: false,
    islogged: reducers.user.isLogged
  }
}

const mapDispatchToProps = (dispatch) => {  
  return { 
    connect: id => {
      dispatch(setConnected(id))
    },
    disconnect: () => {
      dispatch(setDisconnected())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
