import React from 'react';
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import { viewPaymentRequest } from '../actions/app'

import Payments from '../components/Payments'
import Keys from '../components/Keys'
import Doc from '../components/Doc'
import Stats from '../components/Stats'

import $ from 'jquery'


const debug = false


class Welcome extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    ra: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      ts: 0,
      user: {},
      stats: [],
      paymentBack: false,
      paymentdata: null
    }
  }

  componentDidMount(){ 
    $('header').show();
  }

  componentWillMount(){
    let arr, subarr, state = this.state;

    if(this.props.location.search) {
      arr = this.props.location.search.replace('?','').split('&');
      state.paymentdata = {};
      for(var i in arr){
        subarr = arr[i].split('=')
        state.paymentdata[subarr[0]] = subarr[1]
      }

      state.paymentdata.success = (state.paymentdata.error) ? false : true;
      state.paymentBack = true;
      if(state.paymentdata.success)
        this.props.paymentView(state.paymentdata.paymentId);
      else
        state.paymentdata.paymentMessage = "Payment not finished. Cancelled on Paypal side.";

      this.setState(state);
      this.props.history.push("/dashboard/keys");
    } else {
      state.paymentdata = null;
      this.setState(state);
    }
  }

  toolsSwitch(e) {
    let state = this.state
    state.json = !state.json
    this.setState(state)
  } 

  componentWillReceiveProps(next){ 
    // If requests keys was sent this time
    let state = this.state, modified = false, force = false;

    if(!this.props.ra.redir && next.ra.redir && next.ra.redir.length > 0) {
      state.redirect = true;
      modified = true;
    }

    if( (!this.props.ra.data || (this.props.ra.data && !this.props.ra.data.payer)) && (next.ra.data && next.ra.data.payer) ) {
      if(debug)
        console.log(next.ra)
      if(state.paymentdata) {
        if(state.paymentdata.success)
          state.paymentdata.paymentMessage = `Thanks for your payment ${next.ra.data.payer.payer_info.first_name}`;
          
        modified = true;
      }
    }
    
    if( !this.props.sid && next.sid && this.props.user.keys.length === 0 && this.props.location.pathname.indexOf('keys') !== -1) {
      if(debug)
        console.log("- - request keys")
      // this.props.requestUserKeys()
      force = true
    }

    if( this.props.user.user !== next.user.user ) {
      if(debug)
        console.log("- - arrive user data");
      state.user = next.user.user;
      modified = true
      force = true
    }

    if( this.props.user.stats !== next.user.stats ) {
      if(debug)
        console.log("- - update user stats");
      state.stats = next.user.stats;
      modified = true
    }

    if(this.props.user.user && Object.keys(this.props.user.user) > 0) {
      if(debug)
        console.log("- - arrive user data local")
      state.user = this.props.user.user;
      modified = true
      force = true 
    }

    if(this.props.location.pathname.indexOf('account') !== -1 && state.paymentData) {
      state.paymentData = null;
      modified = true;
    }

    if(modified)
      this.setState(state)
    if(force)
      this.forceUpdate()
  }

  render() {
    if(debug)
      console.log("welcome", this.props)

    if(this.state.redirect)
      window.location = this.props.ra.redir

    if(!this.props.user.isLogged)
      return( <Redirect to="/login"/> )
    
    if(this.props.location.pathname.indexOf('doc') === -1 && 
      this.props.location.pathname.indexOf('keys') === -1 && 
      this.props.location.pathname.indexOf('stats') === -1 && 
      this.props.location.pathname.indexOf('account') === -1
    )
      return ( <Redirect to="/dashboard/keys"/> )

    return (
      <div className="welcome">
        { this.props.location.pathname.indexOf('doc') !== -1 && <Doc /> }
        { this.props.location.pathname.indexOf('keys') !== -1 && <Keys paymentData={this.state.paymentdata} /> }
        { this.props.location.pathname.indexOf('stats') !== -1 && <Stats /> }
        { this.props.location.pathname.indexOf('account') !== -1 && <Payments /> }
      </div>
    );
  }
}


const mapStateToProps = (state, props) => {
  return { 
    user: state.user,
    ra: state.ra
  }
}

const mapDispatchToProps = (dispatch) => {  
  return { 
    paymentView: (p) => {
      dispatch(viewPaymentRequest(p))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Welcome)
