import React from 'react';
import { Link } from 'react-router-dom'
import { EP } from '../actions/user'


const debug = false

export default class Doc extends React.Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  componentDidMount() {
    if(debug)
      console.log("Doc", this.props);
  }


  render() { 
    return(
      <div className="doc">
        <div className="thinner">
        <div className="col-md-10">
          <h3>Use the Application</h3>
          <p>
            In order to get a classification for any url address, you must send a POST request to the following endpoint:<br/>
          </p>
          <pre>POST {EP}/classify?key=<b>your_key</b></pre>
        </div>
        <div className="col-md-10">
          <hr />
          <p>
            Ensure the following request configuration: 
          </p>  
          <div className="opts">
            <div>
              <b>Headers</b>
              <div className="inliner keybox">
                <div className="elem word"><tt>X-Auth</tt></div><div className="elem"><tt>Bearer <b>your_public_id</b></tt></div>
              </div> 
              <div className="inliner keybox">
                <div className="elem word"><tt>Content-Type</tt></div><div className="elem"><tt>application/json</tt></div>
              </div>
              <div className="inliner keybox">
                <div className="elem word"><tt>User-Agent</tt></div><div className="elem"><span>Override the user agent sent to the provied url</span></div>
              </div>
            </div>
          </div>
          <br />  
          <div className="opts">
            <div>
              <b>Post params</b>
              <div className="inliner keybox">
                <div className="elem word"><tt>url</tt></div><div className="elem phrase">Any url including http(s) part</div><div className="elem knum">string:url</div><div className="elem long">Make sure it's the url you want</div>
              </div>
              <div className="inliner keybox">
                <div className="elem word"><tt>mode</tt></div><div className="elem phrase">concrete | sparse</div><div className="elem knum">string</div><div className="elem long">Receive one category for concrete, or a set of categories with each associated distance for sparse</div>
              </div>
              <div className="inliner keybox">
                <div className="elem word"><tt>strainer</tt></div><div className="elem phrase">on | off</div><div className="elem knum">string</div><div className="elem long">Reduce space of text search for this url (faster)</div>
              </div>
            </div>
          </div>  
          <br />  
          <div className="opts">
            <div>
              <b>Url params</b>
              <div className="inliner keybox">
                <div className="elem word"><tt>key</tt></div><div className="elem phrase">Your key</div><div className="elem knum">string</div><div className="elem long">A key you created in your account</div>
              </div>
              <div className="inliner keybox">
                <div className="elem word"><tt>v</tt></div><div className="elem phrase">Klassifier version</div><div className="elem knum">string</div><div className="elem long">Optional. Default is <code>0.5.3</code>. Please, check the available klassifier versions <Link to="/about#analysis">here</Link>.</div>
              </div>
            </div>
          </div>  
          <hr />
        </div>
        <div className="col-md-10">
          <h3>Example request using curl</h3>
          <pre className="yes">
            curl -X POST <br/>
            &nbsp;-H 'Content-Type: application/json' <br/>
            &nbsp;-H 'User-Agent: Mozilla/5.0 (Windows NT 6.3; rv:36.0) Gecko/20100101 Firefox/36.0' <br/>
            &nbsp;-H 'X-Auth: Bearer <b>your_public_id</b>'  <br/>
            &nbsp;-d {"'{\"url\":\"" }<b>any_url</b>{"\", \"mode\":\"sparse\", \"strainer\":\"on\"}'" } <br/>
            &nbsp;{EP}/classify?key=<b>your_key</b>"
          </pre>
          <hr />
        </div>  
        <div className="col-md-12">
          <h4>Responses</h4>
          <p>
            The application will respond with the following json bodies:
          </p>
        </div>
        <div className="col-md-4">
          <tt><b>200 Ok</b></tt><br/>
          <small>mode</small> <tt>sparse</tt>
          <pre className="res">{ JSON.stringify( { status:"ok", "data": [
                { "far": 0.1989, "cat": "Software" },
                { "far": 0.8313, "cat": "Hardware" },
                { "far": 0.9828, "cat": "Internet" },
                { "far": 1.014, "cat": "Libraries" }
              ], "ot": 2733.99 }, 0, 2 ) 
          }</pre>
        </div>
        <div className="col-md-4">
          <tt><b>200 Ok</b></tt><br/>
          <small>mode</small> <tt>concrete</tt>
          <pre className="res">{ JSON.stringify( { status:"ok", "data":["Software"], "ot": 1702.25 }, 0, 2 ) }</pre>
        </div>
        <div className="col-md-10">
          <hr />
        </div>
        <div className="col-md-12">
          <p>
            In case there is an problem fetching the url provided in POST params, the application will respond:
          </p>
        </div>
        <div className="col-md-4">
          <tt><b>200 Ok</b></tt><br/>
          <small>Errors fetching</small> <tt>url</tt>
          <pre className="res">{ JSON.stringify( { status:"warn", "message": "Something happened fetching url text", "ot": 733.99 }, 0, 2 ) }</pre>
        </div>
        <div className="col-md-10">
          <hr />
        </div>
        <div className="col-md-12">
          <p>
            The application handles the following errors:
          </p>
        </div>
        <div className="col-md-4">
          <tt><b>401 Unauthorized</b></tt>
          <pre className="res">{ JSON.stringify({"status": "error", "message": "Unauthorized"}, 0, 2 ) }</pre>
        </div>
 
      </div>
      </div>
    )
  }
}