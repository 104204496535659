import React from 'react';
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import { register } from '../actions/user'
import { validateEmail, validateStr, validateStrExt, goHome } from '../actions/lib'

import moment from 'moment'
import $ from 'jquery'

const debug = false


class Register extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    isFetching: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      to_date: moment(),
      from_date: moment().subtract(3, "days"),
      email_error: null,
      name_error: null,
      password_error: null,
      connect_error: null
    }
  }

  componentDidMount(){

    $('header').hide();
    $('.site-footer').addClass('blue');

    var event = new Event('start-waves');
    if(event.type !== 'start-waves') {
      setTimeout(function(){
        var event = new CustomEvent('start-waves',  {"bubbles":true, "cancelable":false} );
        document.dispatchEvent(event);
      },10)
    } else {
      document.dispatchEvent(event);
    }
  }

  componentWillUnmount(){
    $('.site-footer').removeClass('blue');
  }

  componentWillReceiveProps(next){ 
    // Redirect is available in this case (?)
    if(debug)
      console.log(this.props, next);
  }

  register(){
    let email = $(this.refs.mail).val()
    let name = $(this.refs.name).val()
    let org = $(this.refs.org).val()
    let pass = $(this.refs.pass).val()
    let err = false
    let state = this.state

    let validEmail = validateEmail(email);
    let validName = validateStr(name);
    let validOrg = validateStrExt(org);
    let validPass = (!pass || pass === '' || pass.length < 6) ? false : pass;

    if(!validEmail) {
      state.email_error='Not a valid email...'
      $(this.refs.mail).val("")
      err=true
    } 
    if(!validName) {
      state.name_error='Not a valid name...'
      $(this.refs.name).val("")
      err=true
    } 
    if(!validPass) {
      state.password_error='Password min 6 characters'
      $(this.refs.pass).val("")
      err = true
    } 

    if(!err) {
      state.email_error=null;
      state.name_error=null;
      state.password_error=null;
      this.setState(state)
      this.props.registeruser(validName, validEmail, validPass, validOrg)
    } else {
      this.setState(state)
    }
  }


  render() {
    if(this.state.redirect)
      window.location = this.props.redir

    if(this.props.isLogged)
      return( <Redirect to="/dashboard/keys"/> )

    return ( 
      <div className="login cls">
        <div className="center">
          <div className="col-md-3">
          </div> 
          <div className="col-md-3 headline">
            <span className="text gt">Welcome to</span>
            <h1>Klassif<span className="norm">.io</span></h1>
            <p>
              Web classification made simple
            </p>
            <div>
              <div className="inliner">
                <div className="btn btn-blue">Access</div>
                <div className="btn btn-orange" onClick={()=>{ goHome()}} >Info</div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="text">Enter your name</div>
            <div className={this.state.name_error ? "form-group has-error" : "form-group" }>
              <input type="text" className="form-control" ref="name" placeholder={this.state.name_error ? this.state.name_error : "Your name..." } />
            </div>
            <div className="text">Enter your email</div>
            <div className={this.state.email_error ? "form-group has-error" : "form-group" }>
              <input type="text" className="form-control" ref="mail" placeholder={this.state.email_error ? this.state.email_error : "Your email..." } />
            </div>
            <div className="text">Organization</div>
            <div className="form-group">
              <input type="text" className="form-control" ref="org" placeholder={"If any..."} />
            </div>
            <div className="text">Enter your password</div>
            <div className={this.state.password_error ? "form-group has-error" : "form-group" }>
              <input type="password" className="form-control" ref="pass" placeholder={this.state.password_error ? this.state.password_error : "Password..."} />
            </div>
            <div className="text">An link will be sent to the email address to activate the account.</div>
            { this.props.user.hasErrors && this.props.user.errors.map((e,i) => {
              return(<div key={i} className="text">{e}</div>)
              })
            }
            <hr />
            <div className="row">
              <div className="col-md-5">
                { this.props.app.connected && <button className="btn btn-default" onClick={ () => this.register() }>Register</button> }
                { !this.props.app.connected && <button className="btn btn-default disabled" onClick={ () => {} }>Register</button> }
                { !this.props.app.connected && <div className="text small">connecting</div> }

                { this.props.isFetching && <div className="loading-white"></div> }
              </div>
              <div className="col-md-7">
                <span> or <Link to="/login" className="wl">login</Link> or <Link to="/reset" className="wl">reset</Link> password</span>
              </div>
            </div>
            <hr />
          </div>
          <div className="col-md-3">
          </div> 
          <div className="payment-status">
            <span className="log-text"> {this.state.paymentMessage} </span>
          </div>
        </div>
        <canvas id="myCanvas" resize keepalive="true"></canvas>
      </div>
    );
  }
}


const mapStateToProps = (state, props) => { 
  return { 
    user: state.user,
    app: state.ra,
    isFetching: state.user.isFetching,
    isLogged: state.user.isLogged
  }
}

const mapDispatchToProps = (dispatch) => {  
  return { 
    registeruser: (n, e, p, o) => {
      dispatch(register(n,e,p,o))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Register)
