import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import $ from 'jquery'
import { reset } from '../actions/user'
import { validateEmail, goHome } from '../actions/lib'


const debug = false


class Reset extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    isReseting: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {
      email_error: null,
      back: false
    }
  }

  componentDidMount(){

    $('header').hide();
    $('.site-footer').addClass('blue');

    var event = new Event('start-waves');
    if(event.type !== 'start-waves') {
      setTimeout(function(){
        var event = new CustomEvent('start-waves',  {bubbles: true, cancelable: false});
        document.dispatchEvent(event);
      },10)
    } else {
      document.dispatchEvent(event);
    }
  }

  componentWillUnmount(){
    $('.site-footer').removeClass('blue');
  }


  componentWillReceiveProps(next){ 
    /**
     * Redirect is available in this case
     */
    console.log(this.props, next);
    let state = this.state

    if(this.props.isReseting && !next.isReseting) {
      state.back=true
      this.setState(state);
    }

  }

  reset(){
    let email = $(this.refs.mail).val()
    let valid = validateEmail(email);
    let state = this.state
    
    if(debug)
      console.log(valid, email)

    if(!valid) {
      state.email_error='Not a valid email...'
      $(this.refs.mail).val("")
      this.setState(state)
    } else {
      state.email_error=null;
      this.setState(state)
      this.props.resetpass(valid)
    }

  }

  render() {
    return ( 
      <div className="login cls">
        <div className="center">
          <div className="col-md-3">
          </div> 
          <div className="col-md-3 headline">
            <span className="text gt">Welcome to</span>
            <h1>Klassif<span className="norm">.io</span></h1>
            <p>
              Web classification made easy
            </p>
            <div>
              <div className="inliner">
                <div className="btn btn-blue">Access</div>
                <div className="btn btn-orange" onClick={()=>{ goHome()}} >Info</div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="text">Enter your email...</div>
            <div className={this.state.email_error ? "form-group has-error" : "form-group" }>
              <input type="text" className="form-control" ref="mail" placeholder={this.state.email_error ? this.state.email_error : "Your email..." } />
            </div>
            { this.state.back && <div className="text">An email was sent to {$(this.refs.mail).val()} with a reset link.</div>}
            <hr />
            { !this.state.back && <div className="row">
              <div className="col-md-4">
                { this.props.app.connected && <button className="btn btn-default" onClick={ () => this.reset() }>Reset</button> }
                { !this.props.app.connected && <button className="btn btn-default disabled" onClick={ () => {} }>Reset</button> }
                { !this.props.app.connected && <div className="text small">connecting</div> }

                { this.props.isReseting && <div className="loading-white"></div> }
              </div>
              <div className="col-md-8">
                <span> or <Link to="/login" className="wl">login</Link> or <Link to="/register" className="wl">register</Link></span>
              </div>
            </div> }
            { !this.state.back && <hr /> }
          </div>
          <div className="col-md-3">
          </div> 
          <div className="payment-status">
            <span className="log-text"> {this.state.paymentMessage} </span>
          </div>
        </div>
        <canvas id="myCanvas" resize keepalive="true"></canvas>
      </div>
    );
  }
}


const mapStateToProps = (state, props) => { 
  console.log(state);
  return { 
    user: state.user.user,
    app: state.ra,
    isReseting: state.user.isReseting
  }
}

const mapDispatchToProps = (dispatch) => {  
  return { 
    resetpass: (e) => {
      dispatch(reset(e))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Reset)