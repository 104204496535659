import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_STATS_REQUEST,
  USER_STATS_SUCCESS,
  RESET_REQUEST,
  RESET_RESPONSE,
  RESET_SUCCESS,
  RESET_FAILED,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILED
} from '../actions/user'

import {
  INIT,
  GET_PAYMENTS_SUCCESS,
} from '../actions/app'


const debug = true

const user = (state = {
  isFetching: false,
  hasErrors: false,
  isLogged: false,
  stats: null,
  statsDays: 30,
  payments: null,
  details: null,
  errors: []
}, action) => {

  if (debug)
    console.log("register reducer", state);
  switch (action.type) {
    case INIT:
      return {
        ...state,
        isFetching: false,
        hasErrors: false,
        errors: []
      }

    case 'UPDATE_STATS_DAYS':
      return {
        ...state,
        statsDays: action.data
      }
    
    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasErrors: false,
        errors: [],
        payments: action.data.payset,
      }
    case LOGIN_REQUEST:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isFetching: true
      }
    case RESET_REQUEST:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isReseting: true,
        isFetching: true
      }
    case RESET_RESPONSE:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isFetching: false,
        isReseting: false
      }
    case RESET_SUCCESS:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isFetching: false,
        isReseting: false
      }
    case RESET_FAILED:
      return {
        ...state,
        hasErrors: true,
        errors: [action.error],
        isFetching: false,
        isReseting: false
      }

    case REGISTER_REQUEST:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isFetching: true
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isFetching: false
      }
    case REGISTER_FAILED:
      return {
        ...state,
        hasErrors: true,
        errors: [action.error],
        isFetching: false
      }

    case LOGOUT_REQUEST:
      return {
        ...state,
        hasErrors: false,
        errors: [],
        isFetching: true
      }
    
    case USER_DATA_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case USER_DATA_SUCCESS:
      return {
        ...state,
        details: action.data,
        isFetching: false,
        hasErrors: false
      }
    case USER_STATS_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case USER_STATS_SUCCESS:
      return {
        ...state,
        stats: action.data,
        isFetching: false,
        hasErrors: false
      }
    case LOGIN_FAILED:
      console.log(action);
      return {
        ...state,
        isFetching: false,
        hasErrors: true,
        errors: ['Please, double check your credentials']
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        csfr: action.data,
        errors: [],
        hasErrors: false,
        isLogged: true
      }
    // Always logout
    case LOGOUT_FAILED:
    case LOGOUT_SUCCESS:
      return {
        isFetching: false,
        hasErrors: false,
        isLogged: false,
        hasNoKeys: false,
        errors: [],
        keys: [],
        payments: [],
        stats: null,
        user: null,
        csfr: null
      }
    default:
      return state
  }
}

export default user
