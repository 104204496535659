/**
 * 
 */
import $ from 'jquery'
import md5 from 'md5'
import { EP } from './user'

export const GET_KEYS_REQUEST = 'GET_KEYS_REQUEST'
export const GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS'
export const GET_KEYS_FAIL = 'GET_KEYS_FAIL'

export const CREATE_KEY_REQUEST = 'CREATE_KEY_REQUEST'
export const CREATE_KEY_SUCCESS = 'CREATE_KEY_SUCCESS'
export const CREATE_KEY_FAIL = 'CREATE_KEY_FAIL'

export const DELETE_KEY_REQUEST = 'DELETE_KEY_REQUEST'
export const DELETE_KEY_SUCCESS = 'DELETE_KEY_SUCCESS'
export const DELETE_KEY_FAIL = 'DELETE_KEY_FAIL'

const getKeysSuccess = (a) => {
  return {
    type: GET_KEYS_SUCCESS,
    data: a, 
    ts: Date.now()
  }
}

const createKeySuccess = (a) => {
  return {
    type: CREATE_KEY_SUCCESS,
    data: a, 
    ts: Date.now()
  }
}

const keyDeleteSuccess = (a) => {
  return {
    type: DELETE_KEY_SUCCESS,
    data: a.key,
    ts: Date.now()
  }
}

const keyDeleteFail = (a) => {
  return {
    type: DELETE_KEY_FAIL,
    data: a,
    ts: Date.now()
  }
}

export const createKeyRequest = () => (dispatch, getState) => {

  var state = getState()
  var ts = Date.now() / 1000
  var data = {
    sign: state.ra.sid,
    song: md5(state.ra.sid+ts),
    ts: ts
  }

  dispatch({
    type: CREATE_KEY_REQUEST,
    ts: Date.now()
  })

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(createKeySuccess(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch({
      type: CREATE_KEY_FAIL,
      error: res.message
    })
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S " + state.user.csfr)
  }

  $.ajax(
    {
      url: EP + '/user/keys',
      method: 'POST',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data,
  });
}

export const requestKeys = () => (dispatch, getState) => {

  var state = getState()

  dispatch({
    type: GET_KEYS_REQUEST,
    ts: Date.now()
  })

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(getKeysSuccess(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch({
      type: GET_KEYS_FAIL,
      error: res.message
    })
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S " + state.user.csfr)
  }

  $.ajax(
    {
      url: EP + '/user/keys',
      method: 'GET',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json'
  });
}

export const deleteKey = (k) => (dispatch, getState) => {

  var state = getState()

  dispatch({
    type: DELETE_KEY_REQUEST,
    ts: Date.now()
  })

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(keyDeleteSuccess({key: k}))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch(keyDeleteFail(res))
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", `S ${state.user.csfr}`);
  }

  $.ajax(
    {
      url: `${EP}/user/keys/${k}`,
      method: 'DELETE',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json'
  });
}
