/**
 * 
 */
import {
  SEND_CONTACT_REQUEST,
  CONTACT_REQUEST_FAIL,
  CONTACT_REQUEST_SUCCESS
} from '../actions/contact'

import { INIT } from '../actions/app'


const contact = (state = {
  isFetching: false,
  hasErrors: false,
  success: false,
  errors: []
}, action) => {
  switch (action.type) {

    case INIT:
      return {
        isFetching: false,
        hasErrors: false,
        success: false,
        errors: []
      }

    case SEND_CONTACT_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasErrors: false,
        success: false,
        errors: []
      }

    case CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        success: true
      }

    case CONTACT_REQUEST_FAIL:
      return {
        ...state,
        isFetching: false,
        hasErrors: true,
        errors: [`Your message was not sent`]
      }
    default: 
      return state
  }
}

export default contact
