import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import $ from "jquery";
import md5 from 'md5'

import { createKeyRequest, requestKeys, deleteKey } from '../actions/keys'
import { userData } from '../actions/user'
import { resetPayment, sendPaymentRequest } from '../actions/app'
import { copyTextToClipboard } from '../actions/lib'

const debug = false
const delay = 30
const tax = 0.21


class Keys extends React.Component {

  static propTypes = {
    paymentData: PropTypes.object,
  }

  constructor(props){
    super(props);
    this.state = {
      quantity: 0,
      price: 0.0,
      tax: tax,
      isFetchingKey: false,
      tsKeysAt: 0,
      showWarning: false,
      warningText: '',
      warningItem: null,
    }
  }

  componentDidMount() {
    let state = this.state
    if(debug)
      console.log("Keys", this.props);
    
    if(this.props.ra.sid) {
      /* eslint-disable react/no-direct-mutation-state */
      state.tsKeysAt = Date.now()
      this.props.requestUserKeys()
      this.props.requestUserData()
      this.setState(state)
    }
  }

  componentWillReceiveProps(props) {
    let state = this.state, updated = false
    if( state.tsKeysAt < Date.now() - delay * 1000 ) {
      this.props.requestUserKeys()
      state.tsKeysAt = Date.now()
      updated = true
    }
    if(!this.props.ra.connected && props.ra.connected) {
      // The app just connected, probably after the mount
      this.props.requestUserKeys()
      state.tsKeysAt = Date.now()
      this.props.requestUserData()
      updated = true
    }
    if(updated)
      this.setState(state)
  }

  updateQuatity() {
    let value = $(this.refs.keys).val(), factor = 0.01, state = this.state

    if(value >= 10000)
      factor = 0.005
    if(value >= 100000)
      factor = 0.0025
    if(value >= 1000000)
      factor = 0.001

    state.price = value * factor
    this.setState(state)
  } 

  showKey(e){
    var pos
    if( $(e.target).attr('data-show') ) {
      pos = $(e.target).parent().attr('data-key');
      copyTextToClipboard(this.props.keys.keys[pos].key);
      if( !$(e.target).parent().find('i')[0] ) {
        $(e.target).parent().append('<i data-copied="true" class="fa fa-files-o end"></i><span data-copied="true" class="end">&nbsp;Copied</span>');
      }

    } else { 
      if( $(e.target).attr('data-key') ){
        pos = $(e.target).attr('data-key');
        console.log(e, e.target.currentTarget)
        console.log(pos, this.props.keys.keys[pos]);

        $(e.target).addClass('selected').html('<span data-show="true">'+this.props.keys.keys[pos].key+'</span>');

      } else {
        if( $(e.target).attr('data-copied') ){
          
          pos = $(e.target).parent().attr('data-key');
          $(e.target).parent().removeClass('selected');
          $(e.target).parent().html(this.props.keys.keys[pos].key.substring(0,32));
        }
      }
    }
  }

  requestKey() {
    var state = this.state
    this.props.requestkey();
    setTimeout(()=>{
      // Delayed send, in order to ensure that, the previous action
      // was received by the reducer.
      this.props.requestUserKeys();  
    }, 333)
    this.setState(state);
  }

  sendPayment() {

    var payload = {}, items = []
    items.push({
      name: 'apikey',
      sku: md5(Date.now() + 'klassifier-apikey'),
      price: this.state.price - (this.state.price * this.state.tax),
      size: parseInt($(this.refs.keys).val()),
      quantity: 1
    })
    items.push({
      name: 'tax',
      sku: md5(Date.now() + 'klassifier-tax'),
      price: this.state.price * this.state.tax,
      quantity: 1
    })

    payload.items = JSON.stringify(items)
    payload.uid = this.props.user.csfr
    payload.size = items.length

    this.props.paymentRequest(payload)
  }

  showMessage(e){
    var state = this.state
    var a = $(e.target).parent().attr('data-key')
    state.warningItem = a;
    state.showWarning = true;
    state.warningText = ["Delete key", "are you sure?"]
    this.setState(state)
  }

  hideMessage(e){
    var state = this.state
    state.warningItem=null;
    state.showWarning=false;
    state.warningText=""
    this.setState(state)
  }

  deleteKey(){
    var a=this.state.warningItem;
    console.log(a);
    this.props.requestDeleteKey(a);
    this.hideMessage();
  }

  render() { 
    console.log(this.props.keys)
    return(
      <div className="thinner">
        <div className="col-md-12">
          <h3>Your keys</h3>
        </div>
        { this.props.keys.keys.length > 0 && <div className="col-md-8">
          <div className="inliner keybox header">
            <div className="elem kid">Key <span className="small">Truncated - click to copy</span></div>
            <div className="elem ktext">Created</div>
            <div className="elem ktext">Last used</div>
            <div className="elem kunit">Hits</div>
            <div className="elem del"></div>
          </div>
          { this.props.keys.keys.map((e, i)=>{
              return(<div key={i} className="inliner keybox">
                <div className="elem kid" data-key={i} onClick={(e)=>{this.showKey(e)}}>{e.key.substring(0,32)}</div>
                <div className="elem ktext">{e.created.substring(0,16)}</div>
                <div className="elem ktext">{e.lastused ? e.lastused.substring(0,16) : 'Never'}</div>
                <div className="elem kunit">{e.timesused}</div>
                <div className="elem del" data-key={e.key}><i onClick={(e)=>this.showMessage(e)} className="fa fa-times red"></i></div>
              </div>)
          }) }
        </div> }
        { this.props.keys.keys.length === 0 && <div className="col-md-8">
          <div className="inliner keybox header">
            <div className="elem kid">Key <span className="small">Truncated</span></div>
            <div className="elem ktext">Created</div>
            <div className="elem ktext">Last used</div>
            <div className="elem kunit">Hits</div>
            <div className="elem del"></div>
          </div>
          { [0,1].map((e, i)=>{ 
              return(<div key={i}  className="inliner keybox">
                <div className="elem kid"><span className="fill">&nbsp;</span></div>
                <div className="elem ktext"><span className="fill">&nbsp;</span></div>
                <div className="elem ktext"><span className="fill">&nbsp;</span></div>
                <div className="elem kunit"><span className="fill">&nbsp;</span></div>
                <div className="elem del"></div>
                </div>
              )
            })
          }
        </div> }
        { this.props.keys.keys.length === 0 && <div className="col-md-8">
          <div className="inliner keybox header">
            <div className="elem kid">Key <span className="small">Truncated</span></div>
            <div className="elem ktext">Created</div>
            <div className="elem ktext">Last used</div>
            <div className="elem kunit">Hits</div>
            <div className="elem del"></div>
          </div>
          { [0].map((e, i)=>{ 
              return(<div key={i}  className="inliner no-keys arrow-right">
                <i className="fa fa-info-circle top"></i>
                <div className="elem">
                  <p className="">There are still no keys in your account.<br />Your keys will be shown here.</p>
                  <p className="">Use the create key button to generate a new key.<br />Keys are needed to authenticate requests sent to the classifier API service.</p>
                </div>
              </div>
              )
            })
          }
        </div> }
        <div className="col-md-4">
          { this.state.showWarning && <div className="warn">
              <h4>{this.state.warningText[0]}</h4>
              <p>{this.state.warningText[1]}</p>
              <div className="btn btn-default" onClick={()=>this.deleteKey()}>Yes</div>
              <div className="btn btn-default" onClick={()=>{this.hideMessage()}}>No</div>
              <hr />
            </div> 
          }
          <h4>Actions</h4>
          <p>
            You've got {this.props.keys.keys.length} {this.props.keys.keys.length === 1 ? 'key' : 'keys'}.<br/>
            {this.props.keys.keys.length < 100 && <span>It's possible to create more.</span> }
            {this.props.keys.keys.length >= 100 && <span>You already have the maximum number of created keys. Delete any if you would like to create more.</span> }
            <br/> 
            There is a maximum of 100 keys per user.<br/>
            <br/>
            The requests available on your account are shared among all keys.
          </p>

          { this.props.keys.keys.length < 100 && <div className="inliner">
            <div className={ this.props.keys.keys.length === 0 ? "btn glow btn-default" : "btn btn-default"} onClick={()=>{this.requestKey()}}>Create Key</div>
            { this.props.keys.isCreating && <div className="loading"></div> }
          </div> }

          <hr />
          <p>
            Read more on how to use keys <Link to="/dashboard/doc">here</Link> or always <Link to="/contact">contact us</Link> for help.
          </p>

        </div>
        <div className="col-md-12">
          <hr />
        </div>
        <div className="col-md-12">
          
        </div>
        <div className="col-md-4">
          { this.props.user.user && this.props.user.user.requests === 0 && <div>
            <p className="no-keys">
              <i className="fa fa-info-circle top"></i>
              In order to get more requests, you can use the form below.
            </p>
            { this.state.price === 0 && <p className="no-keys low arrow-bottom">
              Just, select a number of requests to be added:<br />
            </p> }
          </div>}
          <h3>Load more requests</h3>

          { this.props.user.details && <div className="form">
            <div className="text">Select the number of requests:</div>
            <select className={ (this.props.user.details.requests === 0 && this.state.price === 0) ? "glow form-control" : "form-control"} defaultValue="-" ref="keys" onChange={()=>{ this.updateQuatity() }}>
              <option value="0">-</option> 
              <option value="100">100</option> 
              <option value="500">500</option> 
              <option value="1000">1K</option> 
              <option value="2000">2K</option> 
              <option value="10000">10K</option> 
              <option value="100000">100K</option> 
              <option value="1000000">1M</option> 
            </select>
            { this.state.price > 0 && <div className="paypal-text">
              <table className="prices">
                <thead>
                <tr>
                  <th></th>
                  <th>Amount</th>
                </tr></thead>
                <tbody>
                  <tr><td>Price</td><td>{(this.state.price - (this.state.price * this.state.tax)).toFixed(2)} €</td></tr>
                  <tr><td>Tax <span className="small"><small>{this.state.tax * 100} %</small></span></td><td>{(this.state.price * this.state.tax).toFixed(2)} €</td></tr>
                  <tr><td>Total</td><td>{parseFloat(this.state.price).toFixed(2)} €</td></tr>
                </tbody>
              </table>
            </div> }
            <hr />

            {!this.props.paymentData && !this.state.paymentBack && this.state.price > 0 &&
              <div className="text">
                <span>Proceed to payment</span>
                <img src="/img/paypal.png" className="form-image" onClick={()=>{this.sendPayment()}} alt="Paypal" />
                <span>&nbsp;</span><br />
                <small>By clicking the button a transation with Paypal will start.
                    You will be redirected to complete the payment.
                </small>
              </div> 
            }
            { this.props.ra.isFetching && <div className="loading"></div> }

            { this.props.paymentData && this.props.paymentData.paymentMessage && 
              <div className="text">
                { this.props.paymentData.success && <div className="feedback">
                  <h4>Payment successful</h4> <i onClick={() => this.props.resetPayment()} className="fa fa-check g"></i>
                  <span className="log-text"> {this.props.paymentData ? this.props.paymentData.paymentMessage : null } </span>
                </div> }
                { !this.props.paymentData.success && <div className="feedback err">
                  <h4>Payment failed</h4> <i onClick={() => this.props.resetPayment()} className="fa fa-times red"></i>
                  <span className="log-text"> {this.props.paymentData ? this.props.paymentData.paymentMessage : null } </span>
                </div> }

                <div className="payment-status small">
                  { this.props.paymentData.success ?
                    "Your payment was received and your available requests updated." :
                    "Your payment couldn't be processed."
                  }
                </div>
              </div> 
            }
            
            { !this.props.ra.isFetching && ( !this.props.paymentData ) && this.props.ra.redir && 
              <span className="log-text">Connecting to Paypal...</span> 
            }
          </div> }
        </div>
        <div className="col-md-8">
          <h3>Request pricing</h3>
          <table className="prices">
            <thead>
            <tr>
              <th></th>
              <th>Early start</th>
              <th>Reasonable</th>
              <th>Sistematic</th>
              <th>Big</th>
            </tr></thead>
            <tbody>
              <tr><td>Requests</td><td>{"< 10K"}</td><td>{"< 100K"}</td><td>{"< 1M"}</td><td> > 1M</td></tr>
              <tr><td>Price</td><td>0.01 €</td><td>0.005 €</td><td>0.0025 €</td><td>0.001 €</td></tr>
            </tbody>
          </table>
          <hr/>
          <p>
            Each request send to the system, provided with <i>a valid response</i>, is reduced from the total requests number in your account.
            You can read <Link to="/about#keys">here</Link> about the cases in which the request is not counted as valid, therefore, doesn't
            decrease the number of requests on your account.
          </p>
          <p>
            Request prices might change to lower prices during time. Read more about the terms on request pricing <Link to="/about#keys">here</Link>
          </p>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return { 
    user: state.user,
    ra: state.ra,
    keys: state.keys
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    paymentRequest: d => {
      dispatch(sendPaymentRequest(d))
    },
    requestUserData: () => {
      dispatch(userData())
    },
    requestkey: () => {
      dispatch(createKeyRequest())
    },
    requestUserKeys: () => {
      dispatch(requestKeys())
    },
    requestDeleteKey: (k) => {
      dispatch(deleteKey(k))
    },
    resetPayment: () => {
      dispatch(resetPayment())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Keys)
