import React from 'react';
import { Link } from 'react-router-dom'
import $ from "jquery";

const debug = false


export default class Footer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      selected: []
    }
  }

  componentDidMount() {
    if(debug)
      console.log("Footer", this.props);
  }

  selectKey(e){
    let state = this.state
    if( state.selected.indexOf($(e.target).attr('data-sel')) === -1 ) {
      state.selected.push($(e.target).attr('data-sel'))
    } else {
      var attr=$(e.target).attr('data-sel');
      state.selected=state.selected.filter(e => e !== attr);

    }
    this.setState(state);
  }

  render() { 
    return(
      <footer className="site-footer">
        <div className="row">
          <div className="col-md-3">
            <ul>
              <li><Link className="title" to="/about#klassif.io">Klassif.io</Link></li>
              <li><Link to="/about#usage">Usage</Link></li>
              <li><Link to="/about#keys">Keys</Link></li>
              <li><Link to="/about#limits">Limits</Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <li><Link className="title" to="/about#classification">Classification</Link></li>
              <li><Link to="/about#brief">Brief</Link></li>
              <li><Link to="/about#analysis">Analysis</Link></li>
              <li><Link to="/about#sources">Sources</Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <li><Link className="title" to="/">Service</Link></li>
              <li><Link to="/about#site">Site</Link></li>
              <li><Link to="/about#users">Users</Link></li>
              <li><Link to="/about#purchases">Purchases</Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <li><Link className="title" to="/">About</Link></li>
              <li><Link to="/about#terms">Service Terms</Link></li>
              <li><Link to="/about#privacy">Privacy policy</Link></li>
              <li><Link to="/about#disclose">Disclose</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}
