/**
 * socket.io-client connection
 * https://socket.io/docs/v4/client-api/#io-url-options
 */

// var host = 'http://localhost:3333'
var host = 'https://s.klassif.io'
var socket = io(host, {
    path: '',
    namespace: '',
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10
});

export { socket }
