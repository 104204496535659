import { combineReducers } from 'redux'

import ra from './app'
import user from './user'
import keys from './keys'
import payment from './payment'
import contact from './contact'


const Reducer = combineReducers({
  ra, user, keys, payment, contact
})

export default Reducer
