import {
  SET_CONNECTED,
  SET_DISCONNECTED,
  VIEW_PAYMENTS_SUCCESS,
  VIEW_PAYMENTS_REQUEST,
  VIEW_PAYMENTS_FAIL,
  // GET_PAYMENTS_SUCCESS, in user reducer
  GET_PAYMENTS_REQUEST,
  GET_PAYMENTS_FAIL,
  SEND_FAIL,
  
  PAYMENT_REQUEST_FAIL,
  PAYMENT_REQUEST_SUCCESS,
  SEND_PAYMENT_REQUEST,
  RESET_PAYMENT,
  ACTIVATION_REQUEST,
  ACTIVATION_SUCCESS,
  INIT
} from '../actions/app'


const ra = (state = {
  data: null,
  redir: null,
  ts: null,
  sid: null,
  acts: 0,
  isFetching: false,
  connected: false,

  isActivating: false,  // this belongs to user
  isActive: false       // this belongs to user

}, action) => {

  switch (action.type) {

    case INIT:
      return {
        redir: null,
        ts: null,
        acts: 0,
        sid: null,
        isFetching: false,
        isActivating: false,
        isActive: false,
        online: false,
        data: null
      }

    // this belongs to user
    case ACTIVATION_REQUEST:
      return {
        ...state,
        acts: Date.now() / 1000,
        isActivating: true
      }

    // this belongs to user
    case ACTIVATION_SUCCESS:
      return {
        ...state,
        isActivating: false,
        isActive: true
      }

    case SET_CONNECTED:
      return {
        ...state,
        connected: true,
        sid: action.data,
        ts: action.ts
      }

    case SET_DISCONNECTED:
      return {
        ...state,
        connected: false,
        sid: null,
        ts: action.ts
      }

    case GET_PAYMENTS_REQUEST:
      return {
        ...state,
        // reset payment results
        data: null
        // TODO: update this
        // can ause desync so no changes in this reducer
        // isFetching: true
      }
    /* TODO: This is handled in user, fix this
      by creating the payments reducer

    case GET_PAYMENTS_SUCCESS: {
      return {
        ...state,
        isFetching: false
      }
    }
    */
    case GET_PAYMENTS_FAIL:
      return {
        ...state,
        isFetching: false
      }

    case VIEW_PAYMENTS_REQUEST: {
      return {
        ...state,
        isFetching: true
      }
    }
    case VIEW_PAYMENTS_SUCCESS: {
    // case VIEW_SUCCESS: {
      return {
        ...state,
        data: action.data.data,
        ts: action.ts,
        isFetching: false
      }
    }
    // review this, check fields
    case VIEW_PAYMENTS_FAIL: {
      return {
        ...state,
        data: {
          status: "error",
          message: action.message
        },
        ts: action.ts,
        isFetching: false,
        isActivating: false,
        isActive: false,
        acts: 0
      }
    }

    case SEND_PAYMENT_REQUEST: {
      return {
        ...state,
        isFetching: true
      }
    }
    case PAYMENT_REQUEST_SUCCESS: {
    // case SEND_SUCCESS: {
      return {
        ...state,
        data: action.data,
        redir: action.data.url,
        ts: action.ts,
        isFetching: false
      }
    }
    // review this, check fields
    case PAYMENT_REQUEST_FAIL: {
      return {
        ...state,
        data: {
          status: "error",
          message: action.message
        },
        ts: action.ts,
        isFetching: false,
        isActivating: false,
        isActive: false,
        acts: 0
      }
    }

    case RESET_PAYMENT:
      return {
        ...state,
        data: null,
        isFetching: false,
        isActivating: false,
        isActive: false,
      }

    /**
     * @deprecated
     */
    case SEND_FAIL: {
      return {
        ...state,
        data: {
          status: "error",
          message: action.message
        },
        ts: action.ts,
        isFetching: false,
        isActivating: false,
        isActive: false,
        acts: 0
      }
    }

    default:
      return state;
  }
}

export default ra
