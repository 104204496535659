/**
 * 
 */
import $ from 'jquery'
import md5 from 'md5'
import { EP } from './user'

export const INIT = "@@redux/INIT"
export const APP_SETUP = 'APP_SETUP'
export const REGISTER_SETUP = 'REGISTER_SETUP'
export const RESET_SETUP = 'RESET_SETUP'

export const REGISTER_SETUP_SUCCESS = 'REGISTER_SETUP_SUCCESS'
export const REGISTER_SETUP_FAIL = 'REGISTER_SETUP_FAIL'
export const RESET_SETUP_SUCCESS = 'RESET_SETUP_SUCCESS'
export const RESET_SETUP_FAIL = 'RESET_SETUP_FAIL'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS'
export const REGISTER_REQUEST_FAIL = 'REGISTER_REQUEST_FAIL'

export const SEND_MAIL = 'SEND_MAIL'
export const SEND_FAIL = 'SEND_FAIL'

export const SEND_PAYMENT_REQUEST = 'SEND_PAYMENT_REQUEST'
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS'
export const PAYMENT_REQUEST_FAIL = 'PAYMENT_REQUEST_FAIL'

export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST'
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS'
export const GET_PAYMENTS_FAIL = 'GET_PAYMENTS_FAIL'

export const VIEW_PAYMENTS_REQUEST = 'VIEW_PAYMENTS_REQUEST'
export const VIEW_PAYMENTS_SUCCESS = 'VIEW_PAYMENTS_SUCCESS'
export const VIEW_PAYMENTS_FAIL = 'VIEW_PAYMENTS_FAIL'

export const RESET_PAYMENT = 'RESET_PAYMENT'

export const LIVE_SUCCESS = 'LIVE_SUCCESS'
export const LIVE_FAIL = 'LIVE_FAIL'
export const LIVE_RELOAD_SUCCESS = 'LIVE_RELOAD_SUCCESS'

export const SET_CONNECTED = 'SET_CONNECTED'
export const SET_DISCONNECTED = 'SET_DISCONNECTED'

export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL'


export const setConnected = (sid) => {
  return {
    type: SET_CONNECTED,
    data: sid,
    ts: Date.now()
  }
}

export const setDisconnected = () => {
  return {
    type: SET_DISCONNECTED,
    ts: Date.now()
  }
}

const paymentSuccess = (a) => {
  return {
    type: PAYMENT_REQUEST_SUCCESS,
    data: a, 
    ts: Date.now()
  }
}

const activationSuccess = (a) => {
  return {
    type: ACTIVATION_SUCCESS,
    data: a, 
    ts: Date.now()
  }
}

const activationRequest = (a) => {
  return {
    type: ACTIVATION_REQUEST,
  }
}

const getPaymentsSuccess = (a) => {
  return {
    type: GET_PAYMENTS_SUCCESS,
    data: a, 
    ts: Date.now()
  }
}

const viewPaymentSuccess = (a) => {
  return {
    type: VIEW_PAYMENTS_SUCCESS,
    data: a, 
    ts: Date.now()
  }
}

const paymentError = (a) => {
  return {
    type: PAYMENT_REQUEST_FAIL,
    data: a, 
    ts: Date.now()
  }
}

const defaultFailure = (a) => {
  return {
    type: SEND_FAIL,
    data: a, 
    ts: Date.now()
  }
}


export const sendPaymentRequest = (data) => (dispatch, getState) => {

  // var state = getState()

  dispatch({
    type: SEND_PAYMENT_REQUEST,
    ts: Date.now()
  })

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(paymentSuccess(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch(paymentError(res))
  }

  $.ajax(
    {
      url: EP + '/payment',
      method: 'POST',
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data,
      // jsonp: 'json.wrf'
      // querystring.stringify( { album:album} ), 
      // { pass:a, sig:state.register.requestid },
      // { headers: { 'xsrf-token': state.register.csfr } } 
  });
}

export const activation = (salt, solt) => (dispatch, getState) => {
  
  var state = getState()
  var ts = Date.now() / 1000
  var data = {
    sign: state.ra.sid,
    song: md5(state.ra.sid+ts),
    sun: salt,
    mars: solt,
    ts: ts
  }

  dispatch(activationRequest())

  const mySuccessCallback = res => {
    console.log(res)
    if(res.message === 'active')
      dispatch(activationSuccess(res))
    else
      dispatch(defaultFailure(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch(paymentError(res)) // foo
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", "S "+state.ra.sid);
  }

  $.ajax(
    {
      url: EP + '/user/activate',
      method: 'POST',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
      data: data,
  });
}

export const requestPayments = () => (dispatch, getState) => {

  var state = getState()

  dispatch({
    type: GET_PAYMENTS_REQUEST,
    ts: Date.now()
  })

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(getPaymentsSuccess(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch({
      type: GET_PAYMENTS_FAIL,
      data: res, 
      ts: Date.now()
    })
  }
  const addHeaders = req => {
    req.setRequestHeader("xsrf-stuff", `S ${state.user.csfr}`)
  }

  $.ajax(
    {
      url: EP + '/user/payments',
      method: 'GET',
      beforeSend: addHeaders,
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json',
  });
}

export const viewPaymentRequest = (uri) => (dispatch, getState) => {

  // var state = getState()
  // console.log(state, uri)

  dispatch({
    type: VIEW_PAYMENTS_REQUEST,
    ts: Date.now()
  })

  const mySuccessCallback = res => {
    console.log(res)
    dispatch(viewPaymentSuccess(res))
  }
  const myErrorCallback = res => {
    console.log(res)
    dispatch({
      type: VIEW_PAYMENTS_FAIL,
      data: res, 
      ts: Date.now()
    })
  }

  $.ajax(
    {
      url: `${EP}/payment/view/${uri}`,
      method: 'GET',
      success: mySuccessCallback,
      error: myErrorCallback,
      dataType: 'json'
  });
}

export const resetPayment = () => (dispatch, getState) => {
  dispatch({
    type: RESET_PAYMENT
  })
}
