import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { sendContact } from '../actions/contact'
import $ from "jquery";
import { validateStr } from '../actions/lib'


const debug = true

class Contact extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    sendContact: PropTypes.func
  }

  constructor(props){
    super(props);
    this.state = {
      tsPaymentsAt:0,
      back:false,
      briefError:null,
      textError:null
    }
  }

  componentWillReceiveProps(next){
    let state = this.state
    if(this.props.user.isSending && !next.user.isSending) {
      state.back=true
      this.setState(state)
    }
  }

  sendForm(){
    let state = this.state
    let topic=$(this.refs.topic).val()
    let brief=$(this.refs.brief).val()
    let text=$(this.refs.text).val()
    let err=false

    let validbrief  =validateStr(brief);
    let validtext   =validateStr(text);

    if(!validbrief) {
      state.briefError = 'Just a sentence'
      $(this.refs.brief).val("")
      err = true
    } else {
      state.briefError = null
    }
    if(!validtext) {
      state.textError = 'It seems a missing message'
      err = true
    } else {
      state.textError = null
    }

    if(!err) {
      state.briefError = null;
      state.textError = null;
      this.setState(state)
      this.props.sendcontact(topic, brief, text)
    } else {
      this.setState(state)
    }
  }

  render() {
    if(debug)
      console.log("Contact >>>", this.props);

    return(
      <div className="contact">
        <div className="thinner">
          <div className="col-md-12">
            <h3>Contact</h3>
            <p>
              This is the contact section.<br />
              Please, feel free to contact us for any question you may have about the service.
            </p>
            <hr />
          </div>
          <div className="col-md-8">
            
            <div className="row">
              <div className="col-md-6">
                <div className="text">Please select one:</div>
                <div className="form-group">
                  <select className="form-control" ref="topic">
                    <option value="0">Need usage help</option>
                    <option value="1">The service works great</option>
                    <option value="2">The service doesn't work</option>
                    <option value="3">Error creating key</option>
                    <option value="4">Error processing payment</option>
                    <option value="5">Unespected classification in response</option>
                    <option value="6">Empty response</option>
                    <option value="7">Cannot fetch a url</option>
                    <option value="8">Whatever</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <span>&nbsp;</span><br />
                <div className="row">
                  <div className="col-md-2">
                    <i className="fa fa-envelope-o big"></i>
                  </div>
                  <div className="col-md-10">
                    <small>
                      By selecting a topic, you will help to address your contact to the right person.
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div className="text">The reason of your contact</div>
            <div className={this.state.briefError ? "form-group has-error" : "form-group" }>
              <input type="text" className="form-control" ref="brief" placeholder={this.state.briefError ? this.state.briefError : "few words brief..." } />
            </div>
            <div className="text">Enter your message</div>
            <div className={this.state.textError ? "form-group has-error" : "form-group" }>
              <textarea className="form-control" ref="text" placeholder={this.state.textError ? this.state.textError : "your message goes here"}></textarea>
            </div>
            <hr />
            <div className="row">
              { !this.state.back && <div className="col-md-12 acc">
                <div className="inliner pull-right">
                  { this.props.contact.isFetching && <div className="loading"></div> }
                  <button className="btn btn-default" onClick={()=>{ this.sendForm()}} >Send</button>
                </div>
              </div> }
              { this.state.back && <div className="col-md-12"> 
                <div className="text pull-right">Message was sent<br /><small>You will receive a reply soon</small></div>
              </div> }
            </div>
          </div>
          <div className="col-md-4">
            <h3>Notice</h3>
            <p>
              You will receive a reply shortly<br /><small>(within the next 1-2 days)</small><br />
            </p><p>  
              We give reply all requests and we are always happy to receive feedback about your experience with the classifier.
            </p>
            <hr />
            <h3>Support</h3>
            <p>
              In case it is an issue related to the service, 
              provide a complete description so we can address it easily.
            </p>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state, props) => { 
  console.log(state);
  return { 
    user: state.user,
    contact: state.contact,
    isFetching: state.user.isSending
  }
}

const mapDispatchToProps = (dispatch) => {  
  return { 
    sendcontact: (t, b, txt) => {
      dispatch(sendContact(t,b,txt))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Contact)