import React from 'react';
import { Link } from 'react-router-dom'
import $ from "jquery";

const debug = true

class Header extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      track: null,
      albumId: 0,
      showlogin: false,
      profileboxVisible: false,
      sidebar: false
    }
  }

  next(e,a){
    if(debug)
      console.log(e, a);
    
    e.preventDefault();
    e.stopPropagation();
    this.props.next(a);
  }

  prev(e,a){
    e.preventDefault();
    e.stopPropagation();
    this.props.prev(a); 
  }

  componentDidMount() {
    let state = this.state
    state.profileboxVisible = false;
    state.showlogin = false;
    this.setState(state);
  }

  toggleProfileBox() {
    let state = this.state
    state.profileboxVisible = !state.profileboxVisible;
    this.setState(state);

    this.props.toggle()
  }

  showlogin(e) {
    let state = this.state
    e.preventDefault();

    state.showlogin=true;
    this.setState(state);
  }

  toggleSidebar() {
    let state = this.state
    if(!state.sidebar) {
      state.sidebar=true
      $('.sidebar').animate({width:'20%'})
    }
    else {
      state.sidebar=false
      $('.sidebar').animate({width:'0%'})
    }
    this.setState(state)
  }

  render() {
    if(debug) {
      console.log("Header::render", this.props);
    }
    var position = this.props.path.lastIndexOf("/"),
      section = this.props.path.substring(position + 1, this.props.path.length)

    if(!this.state.profileboxVisible) {
      if( $('.sidebar-toggle').hasClass('rotate-90') )
        $('.sidebar-toggle').removeClass('rotate-90')
      $('.user-menu .dropdown-menu').removeClass('open');
    } else {
      $('.sidebar-toggle').addClass('rotate-90');
      $('.user-menu .dropdown-menu').addClass('open');
    }

    return (
      <header className="main-header">
        <nav className="navbar navbar-static-top">
          <a href="/" className="sidebar-toggle" onClick={ (e)=> { e.preventDefault(); this.toggleSidebar(e) }} role="button">
            <span className="sr-only"> Toggle navigation </span>
            <span className="icon-bar">  </span>
            <span className="icon-bar">  </span>
            <span className="icon-bar">  </span>
          </a>
          <ul className="dropdown-menu opts">
            <li className="header"> You have 4 messages </li>
            <li className="header"> You have 4 messages </li>
            <li className="header"> You have 4 messages </li>
          </ul>
          
          <div className="hold">
            <ul className="nav navbar-nav">
              <li className="fill"><Link to="/dashboard/keys">&nbsp;</Link></li>
              {this.props.islogged && <li className="">
                <Link to="/dashboard/keys"    className={section === 'keys'    ? "active":"" }>Keys</Link>
                <Link to="/dashboard/stats"   className={section === 'stats'   ? "active":"" }>Stats</Link>
                <Link to="/dashboard/doc"     className={section === 'doc'     ? "active":"" }>Doc</Link>
                <Link to="/dashboard/account" className={section === 'account' ? "active":"" }>Account</Link>
              </li> }
              <li className="">
                <Link to="/about" className="about"><span className="listen"><i className="fa fa-question-circle-o"></i></span></Link>
              </li>
            </ul>

            <ul className="nav navbar-nav pull-right monitor">
              <li className="">
                <a href="/" onClick={(e) => e.preventDefault()}><span
                    className={this.props.isConnected ? "infodot active" : "infodot"}
                    title={this.props.isConnected ? "Connected" : "Disconnected"}
                  ></span></a>
              </li>
              <li className="fill"><Link to="/dashboard/keys">&nbsp;</Link></li>
            </ul>

          </div>
        </nav>
      </header>
    )
  }
}

export default Header
