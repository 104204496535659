/**
 * Utils library
 */

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
}

export const validateEmail = function(str){
  /* eslint-disable no-useless-escape */
  var emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var arr = emailRe.exec(str)
  if(arr && arr.length > 0) {
    return arr[0]
  }
  else return false
}

export const validateURL = function(str) {
  /* eslint-disable no-useless-escape */
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if(!regex.test(str)) {
    return false;
  } else {
    return true;
  }
}

export const validateStr = function(str){
  var re = /[a-zA-Z ]+/;
  var arr = re.exec(str)
  if(arr && arr.length > 0) {
    return arr[0]
  }
  else return false
}

export const validateStrExt = function(str){
  var emailRe = /[A-Za-z _\-0-9]/;
  var arr = emailRe.exec(str)
  if(arr && arr.length > 0) {
    return arr[0]
  }
  else return false
}

export const goHome = () => {
  window.location = 'https://klassif.io/';
}

export const fallbackCopyTextToClipboard = function(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  // textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export const copyTextToClipboard = function(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    // console.log('Async: navigator.clipboard missing');
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}
